import React from 'react';
import FileUploader from "../../components/FileUploader/FileUploader";
import { useParams } from 'react-router-dom';

const CreatePrograms = () => {
    const { clientId } = useParams();
    return (
        <div className="col-lg-9">
        <div className="p-3">
            <h4>Programs</h4>
            <FileUploader accepts={'.pdf,image/*,audio/*,video/* , .doc'} path={'/provider/program'} nav={`/p/client/my-programs/${clientId}`} type={"program"} />
        </div>
    </div>
    );
};

export default CreatePrograms;