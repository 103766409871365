import React, {useState} from 'react';
import {AiOutlineRight} from "react-icons/ai";
import SettingsNav from "../../components/shared/SettingsNav/SettingsNav";
import Form from 'react-bootstrap/Form';

const Settings = () => {
    const [notificationFromClient, setNotificationFromClient] = useState(true)
    return (
        <div className={'bg-light'} style={{height: "100vh"}}>
            <div className={'container pt-5'}>
                <h5 className={'pt-5'}> <span className={'activeBreadCrumb'}><AiOutlineRight/> Settings</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container" style={{height: "80vh"}}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div style={{position: "sticky", top: 0}}>
                                <SettingsNav/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="p-3">
                                <h4>Settings</h4>
                                <br/>
                                <div style={{width:"100%", maxWidth:370,display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <p className={'mb-0 fs-5'}>Notification from client</p>
                                    <Form.Check
                                        className={'switchCheck'}
                                        type="switch"
                                        id="custom-switch"
                                        checked={notificationFromClient}
                                        onClick={()=>setNotificationFromClient(!notificationFromClient)}
                                    />
                                </div>
                                <br/>
                                <div style={{width:"100%", maxWidth:370,display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <p className={'mb-0 fs-5'}>New messages notification</p>
                                    <Form.Check
                                        className={'switchCheck'}
                                        type="switch"
                                        id="custom-switch"
                                        checked={true}

                                    />
                                </div>
                                <br/>
                                <div style={{width:"100%", maxWidth:370,display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <p className={'mb-0 fs-5'}>Get email <br/> <span style={{color:"#8F8F8F", fontSize:13}}> if new client requested to link with them.</span></p>
                                    <Form.Check
                                        className={'switchCheck'}
                                        type="switch"
                                        id="custom-switch"
                                        checked={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;