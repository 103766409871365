import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import styles from './MessageNav.module.css';
import provider from '../../../assets/images/provider.png'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChat, setChats } from '../../../redux/chat/chat';
import axios from 'axios';
import { getSender } from '../../../config/chatLogics';
import { api } from '../../../config/api';

const MessageNav = () => {
    const { pathname } = useLocation();
    // console.log(pathname.split('/')[2] === 'client-info')
    const [loggedUser, setLoggedUser] = useState();
    const { selectedChat, chats, } = useSelector(state => state.chat)
    const {activeUser} = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const fetchChats = async () => {
        try {
            api.get('/chats').then((res) =>
                dispatch(setChats(res.data))
            )
        } catch (err) {
            // toast.error(err);
            console.log(err)
        }
    };
    useEffect(() => {
        fetchChats();
    }, []);
    return (
        <div className="shadow-sm">
            {chats && 
            <div className={'pt-4'}>
                    {chats.map((chat , index) => 
                <div
                onClick={() => dispatch(setSelectedChat(chat))}
                    className={`${selectedChat._id === chat._id && styles.active}   ${styles.navItem}`}>
                    <p className={'d-flex align-items-center m-0'}>
                        <img src={getSender(activeUser, chat.users).imageUri  ?? provider} alt="" className={styles.image} /> <span>{
                      !chat.isGroupChat &&
                      getSender(activeUser, chat.users).userName
                    }</span>
                    </p>
                </div>
                )}
            </div>
}

        </div>
    );
};

export default MessageNav;