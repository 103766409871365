import React, {useState} from 'react';
import providerImage from '../../assets/images/provider.png'
import styles from './LinkedProvider.module.css'
import {FaLock} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import RequestAccessSuccessModal from "../modals/RequestAccessSuccessModal";


const LinkedProvider = ({isLocked}) => {
    const [requestAccessModalShow, setRequestAccessModalShow] = useState(false)
    const navigate = useNavigate()
    return (
        <div className={styles.providerCardContainer}>
            <div className={styles.clientProfileImageContainer}>
                <img src={providerImage} alt="" className={styles.clientProfileImage}/>
                {
                    isLocked &&
                    <div className={styles.overlay}>
                        <FaLock/>
                    </div>
                }
            </div>
            <div>
                {
                    isLocked ? <h4 className={'fw-bold'}
                                   style={{cursor: "pointer"}}>Goe Marsh</h4>
                        :
                        <h4 className={'fw-bold'}
                            style={{cursor: "pointer"}}>Goe Marsh</h4>
                }
                <h6>Occupational Therapist</h6>
                <h6>Clinic: The Allied Health Centre</h6>
                {
                    isLocked ?
                        <p className={styles.submitButton} onClick={()=> setRequestAccessModalShow(true)}>Request access</p>
                        :
                        <p className={styles.submitButton} onClick={() => navigate('provider-info')}>View</p>
                }
            </div>
            <RequestAccessSuccessModal
                show={requestAccessModalShow}
                onHide={() => {
                    setRequestAccessModalShow(false)
                }}
            />
        </div>
    );
};

export default LinkedProvider;