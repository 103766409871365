import Login from "../auth/login/Login";
import ForgotPassword from "../auth/forgotPassword/ForgotPassword";
import ResetPassword from "../auth/ResetPassword/ResetPassword";
import SignUp from "../auth/SignUp/SignUp";
import SubscriptionChoose from "../../pages/SubscriptionChoose/SubscriptionChoose";
import Payment from "../../pages/Payment/Payment";
import MyProfile from "../../pages/MyProfile/MyProfile";
import MyClients from "../../pages/MyClients/MyClients";
import ClientInfo from "../../pages/ClientInfo/ClientInfo";
import MyPrograms from "../../pages/MyPrograms/MyPrograms";
import ProgramDetail from "../../pages/ProgramDetail/ProgramDetail";
import CreatePrograms from "../../pages/CreatePrograms/CreatePrograms";
import Settings from "../../pages/Settings/Settings";
import TermsOfServices from "../../pages/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import HelpAndSupport from "../../pages/HelpAndSupport/HelpAndSupport";
import WriteToUs from "../../pages/WriteToUs/WriteToUs";
import NotFound from "../../pages/NotFound/NotFound";
import MyReports from "../../pages/MyReports/MyReports";
import ReportDetail from "../../pages/ReportDetail/ReportDetail";
import CreateReports from "../../pages/CreateReports/CreateReports";
import MyResources from "../../pages/MyResources/MyResources";
import CreateResources from "../../pages/CreateResources/CreateResources";
import LinkedProviders from "../../pages/LinkedProviders/LinkedProviders";
import ProviderInfo from "../../pages/ProviderInfo/ProviderInfo";
import InviteProvider from "../../pages/InviteProvider/InviteProvider";
import Message from "../../pages/Message/Message";
import ResourceDetail from "../../pages/ResourceDetail/ResourceDetail";
import ClientLayout from "../../layouts/ClientLayout/Clientlayout";
export const publicRoute = [
  {
    path: "*",
    element: <NotFound />,
  }
];
export const redirectIfAuthenticatedRoutes = [{
  path: "/login",
  element: <Login />,
},
{
  path: "/forgot-password",
  element: <ForgotPassword />,
},
{
  path: "/reset-password",
  element: <ResetPassword />,
},
{
  path: "/register",
  element: <SignUp />,
},
];
export const PrivateRoutes = [
  { path: "/p/my-clients", element: <MyClients /> },
  { path: "/p/payment", element: <Payment /> },
  { path: "/p/my-profile", element: <MyProfile /> },
  { path: "/p/my-clients", element: <MyClients /> },
  { path: "/p/client", element: <ClientLayout />, 
  childrens:[
    { path: "client-info/:clientId", element: <ClientInfo /> },
    { path: "my-programs/:clientId", element: <MyPrograms />},
    { path: "my-reports/:clientId", element: <MyReports />},
    { path: "my-resources/:clientId", element: <MyResources /> },
    { path: "my-programs/program-detail/:clientId/:programId", element: <ProgramDetail /> },
    { path: "my-reports/report-detail/:clientId/:reportId", element: <ReportDetail /> },
    { path: "my-programs/create-program/:clientId", element: <CreatePrograms /> },
    { path: "my-reports/create-report/:clientId", element: <CreateReports /> },
    { path: "my-resources/create-resources/:clientId", element: <CreateResources /> },
    {
      path: "linked-providers/:clientId/provider-info/:providerId",
      element: <ProviderInfo />
    },
    { path: "my-resource/resource-detail/:clientId/:resourceId", element: <ResourceDetail /> },
  ] 
},
  // { path: "/p/client-info/:clientId", element: <ClientInfo /> },
  // { path: "/p/my-programs/:clientId", element: <MyPrograms /> },
  // { path: "/p/my-reports/:clientId", element: <MyReports /> },
  // { path: "/p/my-resources/:clientId", element: <MyResources /> },
  // { path: "/p/my-programs/program-detail/:clientId/:programId", element: <ProgramDetail /> },
  // { path: "/p/my-reports/report-detail/:clientId/:reportId", element: <ReportDetail /> },
  // { path: "/p/my-programs/create-program/:clientId", element: <CreatePrograms /> },
  // { path: "/p/my-reports/create-report/:clientId", element: <CreateReports /> },
  // { path: "/p/my-resources/create-resources/:clientId", element: <CreateResources /> },
  { path: "/p/settings", element: <Settings /> },
  { path: "/p/terms-of-services", element: <TermsOfServices /> },
  { path: "/p/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/p/help-and-support", element: <HelpAndSupport /> },
  { path: "/p/write-to-us", element: <WriteToUs /> },
  { path: "/p/linked-providers", element: <LinkedProviders /> },
  { path: "/p/invite-providers", element: <InviteProvider /> },
  { path: "/p/message", element: <Message /> },
  // {
  //   path: "/p/linked-providers/:clientId/provider-info/:providerId",
  //   element: <ProviderInfo />,
  // },
  // { path: "/p/my-resource/resource-detail/:clientId/:resourceId", element: <ResourceDetail /> },
];

export const subscriptionRoute = [
  {
    path: "/p/choose-subscription",
    element: <SubscriptionChoose />,
  },
]