import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineRight } from "react-icons/ai";
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MessageCard from "../../components/MessageCard/MessageCard";
import MessageInput from "../../components/MessageInput/MessageInput";
import MessageNav from "../../components/shared/MessageNav/MessageNav";
import { api } from '../../config/api';
import { getSender, isLastMessage } from '../../config/chatLogics';
import { socket } from '../../config/config';
import { setNotification } from '../../redux/chat/chat';
import styles from './Message.module.css';

var  selectedChatCompare;
const Message = () => {
    const { user, selectedChat, messageNotification, chat } = useSelector(state => state.chat)
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [socketConnected, setSocketConnected] = useState(false);
    const [typing, setTyping] = useState();
    const [isTyping, setIsTyping] = useState();
    const { activeUser } = useSelector(state => state.auth)
    const messageRef = useRef();
    const navigate = useNavigate();
    const dispatch=  useDispatch()
    const fetchAllMessages = async () => {
        if (!selectedChat) return;
        try {
            setLoading(true);
            api.get(`/messages/message/${selectedChat._id}`).then((res) => {
                setMessages(res.data);
                setLoading(false);
                socket.emit("join chat", selectedChat._id);
            })
        } catch (err) {
            // toast.error(err);
            setLoading(false);
            return;
        }
    };

    const sendMessage = async (e) => {

        if (newMessage) {
            socket.emit("stop typing", selectedChat._id);
            try {

                api.post('/messages', {
                    chatId: selectedChat._id,
                    content: newMessage,
                },).then((res) => {
                    socket.emit("new message", res.data);
                    setMessages([...messages, res.data])
                    setNewMessage('')
                })

            } catch (err) {
                console.log(err)
                return;
            }
        }
    };

    const saveNotification = async () => {
        if (!messageNotification?.length) return;
        try {
           await api.post('/messages/notification' , {notification: messageNotification[0].chatId.latestMessage})
        } catch (err) {
            toast.error(err);
        }
    };

    useEffect(() => {
        socket.emit("setup", activeUser);
        socket.on("connected", () => setSocketConnected(true));
        socket.on("typing", () => setIsTyping(true));
        socket.on("stop typing", () => setIsTyping(false));
    }, [activeUser]);

    useEffect(() => {
        fetchAllMessages();
        selectedChatCompare = selectedChat;
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                })
        }
    }, [selectedChat]);

    useEffect(() => {
        socket.on("message received", (newMessageReceived) => {
            if (
                !selectedChatCompare ||
                selectedChatCompare._id !== newMessageReceived.chatId._id
            ) {
                if (!messageNotification.includes()) {
                  
                    messageNotification &&  dispatch(setNotification([newMessageReceived, ...messageNotification]))
                    // setFetchAgain(!fetchAgain);
                }
            } else {
                setMessages([...messages, newMessageReceived]);
                // setNotification([newMessageReceived, ...messageNotification]);
            }
        });
    });
    useEffect(() => {
        saveNotification();
    }, [messageNotification]);
    const typingHandler = (e) => {
        setNewMessage(e.target.value);
        if (!socketConnected) return;
        if (!typing) {
            setTyping(true);
            socket.emit("typing", selectedChat._id);
        }
        let lastTypingTime = new Date().getTime();
        var timerLength = 3000;
        setTimeout(() => {
            var timeNow = new Date().getTime();
            var timeDiff = timeNow - lastTypingTime;
            if (timeDiff >= timerLength && typing) {
                socket.emit("stop typing", selectedChat._id);
                setTyping(false);
            }
        }, timerLength);
    };
    return (
        <div className={'bg-light'} style={{ height: "100vh" }}>
            <div className={'container pt-5 position-relative'}>
                <h5 className={'pt-5'}>Clients <span className={'activeBreadCrumb'}><AiOutlineRight /> Messages</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container " style={{ height: "80vh" }}>
                    <div className="row">
                        <div style={{cursor : 'pointer'}} onClick={()=> navigate(-1)}><BiLeftArrowAlt size={30}/></div>
                        <div className="col-lg-3">
                            <div style={{ position: "sticky", top: 0 }}>
                                <MessageNav />
                            </div>
                        </div>
                        {selectedChat &&
                            <div className="col-lg-9 h-100 position-relative" ref={messageRef}>
                                <div className={`p-3 ${styles.messageContainer}`}>
                                    {selectedChat.users && <h4>{getSender(activeUser, selectedChat.users).userName}</h4>}
                                    <div>
                                        {messages &&
                                            messages.map((message, index) => (
                                                <MessageCard key={index} message={message.content} isLast={isLastMessage(messages, index, activeUser._id)} sent={activeUser._id === message.sender._id} received={!(activeUser._id === message.sender._id)} name={getSender(activeUser, selectedChat.users).userName} senderImage={getSender(activeUser, selectedChat.users).imageUri} userImage={activeUser.imageUri} />
                                            ))}

                                    </div>
                                </div>

                            </div>
                        }
                    </div>

                </div>
                {selectedChat &&
                    <div className={`${styles.inputDiv} row`}>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                            <MessageInput newMessage={newMessage} typingHandler={(e) => typingHandler(e)} sendMessage={(e) => sendMessage(e)} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Message;