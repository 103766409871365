import "./App.css";
import {BrowserRouter } from "react-router-dom";
import Master from "./Master";
import ProviderRoutes from "./components/Routes/ProviderRoutes";

function App() {
    /*const [currentRoute, setCurrentRoute] = useState('')

    useEffect(()=>{
        setCurrentRoute(window.location.pathname.split('/')[1])
    },[])*/

  return (

    <div>
        <BrowserRouter>
            <ProviderRoutes/>
        </BrowserRouter>
    </div>
  );
}

export default App;
