import React from 'react';
import styles from './MessageCard.module.css'
import provider from '../../assets/images/provider.png'

const MessageCard = ({message, isLast, sent, received , name , senderImage , userImage}) => {

    return (
        <>
            {sent &&
                <div className={styles.sentMessageContainer}>
                    <p className={styles.sentMessage}>{message}</p>
                    
                      
             <img src={userImage ?? provider} alt={name?.slice(0,1).toUpperCase()} className={styles.image}/>
                      
                    
                </div>
            }
            {
                received &&
                <div className={styles.receivedMessageContainer}>
                    
                        <img src={senderImage ?? provider} alt={name?.slice(0,1).toUpperCase()} className={styles.image}/>
                          
                    
                    <p className={styles.receivedMessage}>{message}</p>
                </div>
            }
        </>
    );
};

export default MessageCard;