import React from 'react';
import PaymentCard from "../../components/PaymentCard/PaymentCard";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Payment = () => {
    const stripePromise = loadStripe('pk_test_51MCE8yLo3M7OoRNpfQ0gcPAoJjxyVQzR8tM9yYblWUzVNoQPX8mFGDn1lYASEkr2sagpnirto6fqZGsHhd598jCh00tVSLc1nD');


    return (
        <div className={'container mt-5'}>
            <h3 className={'text-center py-5 '}>Payment</h3>

            <div className="row g-4">
                <div className="col-md-6 p-4">
                    <Elements stripe={stripePromise}>
                        <PaymentCard cardType={'method'} />
                    </Elements>
                </div>
                <div className="col-md-6 p-4">
                    <Elements stripe={stripePromise}>
                        <PaymentCard cardType={'summery'} />
                    </Elements>
                </div>
            </div>
            <div>
                <hr style={{ color: "#F5F5F5" }} />
            </div>
        </div>
    );
};

export default Payment;