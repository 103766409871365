import React from 'react';
import ClientNav from "../../components/shared/ClientNav/ClientNav";
import {AiOutlineRight} from "react-icons/ai";
import LinkedProvider from "../../components/LinkedProvider/LinkedProvider";

const LinkedProviders = () => {

    return (
        <div className={'bg-light'} style={{height: "100vh"}}>
            <div className={'container pt-5 position-relative'}>
                <h5 className={'pt-5'}>Clients
                    <span className={'activeBreadCrumb'}><AiOutlineRight/> Client’s info</span>
                    <span className={'activeBreadCrumb'}><AiOutlineRight/> Linked Providers</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container" style={{height: "80vh"}}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div style={{position: "sticky", top: 0}}>
                                <ClientNav/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="p-3">
                                <h4>Linked providers</h4>
                                <LinkedProvider isLocked={false}/>
                                <LinkedProvider isLocked={true}/>
                                <LinkedProvider isLocked={false}/>
                                <LinkedProvider isLocked={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkedProviders;