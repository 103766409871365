import React from 'react';
import styles from './ResourceCard.module.css'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineArrowRight } from "react-icons/ai";
import { changeDateFormat } from '../../config/utils';
import {MdDelete} from 'react-icons/md'
import { api } from '../../config/api';
import Toast from '../Notification/toast';
import { useDispatch } from 'react-redux';
import { fetchResource } from '../../redux/provider/provider';
const ResourceCard = ({ reports}) => {
    const dispatch  = useDispatch()
    const {clientId} = useParams()
    const deleteResource =  (id) =>{
        api.get(`/provider/deleteResource/${id}`).then((res) =>{
            if(res.data.status === "success") {
                dispatch(fetchResource(clientId))
                Toast("Successfully deleted", "success")
            }
        })
    }
    return (
        <div className={'my-4'}>
            {
                Object.entries(reports).map(([key, value]) =>
                    <>
                   
                        <h6 className={styles.date}>{changeDateFormat(key)}</h6>
                        <div className={'row g-4'}>
                            {value.map((val, i) => {
                                return val.resource.map((v, i) => <div className={`col-lg-6 p-2 ${styles.cardRes}`} key={i}>
                                    <Link to={`/p/client/my-resource/resource-detail/${clientId}/${val._id}`}
                                        className={`shadow rounded-left ${styles.customProgramCard}`}>
                                        <h5 className={'py-1 m-0 w-100'}>{v.fileName.replace(/_/g, ' ')}</h5>
                                        <AiOutlineArrowRight className={'fs-4'} />
                                    </Link>
                                    <div className={`rounded-right ${styles.deleteIcon}`}>
                                    <MdDelete size={30} color='white' cursor="pointer" onClick={()=>deleteResource(val._id)} />
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default ResourceCard;