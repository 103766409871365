import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";

export const fetchPrices = createAsyncThunk("courses/fetchPrices", async () => {
  try {
    const { data } = await api.get("/subscription/config");
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchStatus = createAsyncThunk("courses/fetchStatus", async () => {
  try {
    const { data } = await api.get(`/subscription/status`);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const allSubscription = createAsyncThunk(
  "courses/allSubscription",
  async ({ customerId }) => {
    try {
      const { data } = await api.post(`/subscription/getsubscription`, {
        customerId,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const portalSession = createAsyncThunk(
  "courses/portalSession",
  async ({ customerId }) => {
    try {
      const { data } = await api.post(
        "/subscription/create-portal-session",
        { customerId }
      );
      window.location.href = data.uri;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createCustomer = createAsyncThunk(
  "courses/createCustomer",
  async () => {
    try {
      const { data } = await api.post("/subscription/create-customer", {
        email: "",
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createSubscription = createAsyncThunk(
  "courses/createSubscription",
  async ({ priceId, domurl }) => {
    try {
      const { data } = await api.post("/subscription/create-checkout-session", {
        priceId,
        domurl,
      });

      window.location.href = data.uri;
      
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const mySubscriptionSlice = createSlice({
  name: "mySubscription",
  initialState: {
    prices: [],
    subscriptionData: null,
    latestInvoice: null,
    customer: null,
    status: false,
    subscriptions: [],
    invoices: [],
    loading: true,
    currentSubscription: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.fulfilled, (state, { payload }) => {
        if (payload) {
          state.invoices = payload?.allinvoices?.data;
          state.latestInvoice = payload.invoice;
          state.status = payload.substatus;
          state.loading = false;
        }
      })
      .addCase(fetchPrices.fulfilled, (state, { payload }) => {
        if (payload) {
          state.prices = payload.prices;
          state.loading = false;
        }
      })
      .addCase(createCustomer.fulfilled, (state, { payload }) => {
        if (payload) {
          state.customer = payload.customer;
          state.loading = false;
        }
      })
      .addCase(allSubscription.fulfilled, (state, { payload }) => {
        if (payload) {
          state.currentSubscription = payload;
          state.loading = false;
        }
      })
      .addCase(createSubscription.fulfilled, (state, { payload }) => {
        if (payload) {
          state.subscriptionData = {
            subscriptionId: payload.subscriptionId,
            clientSecret: payload.clientSecret,
          };
          state.loading = false;
        }
      })
      .addMatcher((action) => action.rejected, (state, { error }) => {
        console.error(error);
        state.loading = false;
      });
  },
});

export default mySubscriptionSlice.reducer;
