import axios from "axios";
import { toast } from "react-toastify";
import { removeActiveUser , removeToken } from "../redux/auth/auth";
import store from "../redux/store";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
        'content-type': 'application/json',
    }
});

export const apiWithFile = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
        'content-type': 'multipart/form-data',
    }
});
const handleLogout = () => {
    
    localStorage.clear();
    store.dispatch(removeToken());
    store.dispatch(removeActiveUser());
    // Perform any additional cleanup actions as needed
    // Remove any other relevant data from Redux store
    // Redirect to the login page or perform any other necessary action
    window.location.href = "/login";
    window.location.reload()
    toast.warn("Your session has been expired")
  };
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

apiWithFile.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        handleLogout();
      }
  
      return Promise.reject(error);
    }
  );