import React, { useState, useEffect } from 'react';
import styles from './NotesCard.module.css'
import FeedbackModal from "../modals/FeedbackModal/FeedbackModal";
import { api } from '../../config/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const NotesCard = ({ program: { fileName, filePath }, programId }) => {
    const [modalShow, setModalShow] = useState(false);
    const [notes, setNotes] = useState([]);
    const { activeUser } = useSelector(state => state.auth);
    const { myClientById } = useSelector(state => state.myClients);
    const fetchNotes = async () => {
        const res = await api.get(`/provider/notes/${programId}`);
        if (res.data.status == "success") {
            setNotes(res.data.data);
        }
    }
    useEffect(() => {
        fetchNotes();
    }, []);

    const addNote = (note) => {

        api.post('/provider/notes', {
            note,
            programId: programId,
            reciver: myClientById._id

        }).then(res => {
            toast('Successfully added', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            fetchNotes()
        })
    };

    return (
        <div className={'my-4'}>
            <div className="p-3">
                <h4>{fileName}</h4>
                {(fileName.split('.').reverse()[0] == 'doc' || fileName.split('.').reverse()[0] == 'docx') ? <iframe title={fileName} src={`https://docs.google.com/gview?url=${filePath}&embedded=true`} width="100%" height="500px" /> :
                 <iframe title={fileName} src={filePath} width="100%" height="500px" />}
            </div>
            <button className={`btn px-4 my-3 ${styles.feedbackBtn}`} onClick={() => setModalShow(true)}>Add a note</button>
            <span className={`ms-3 ${styles.feedbackCount}`} onClick={() => setModalShow(true)}>{notes.length} Note{notes.length > 0 ? 's' : ''}</span>
            <FeedbackModal
                addNote={addNote}
                show={modalShow}
                notes={notes}
                id={activeUser._id}
                onHide={() => {
                    setModalShow(false)
                }}
            />
        </div>
    );
};

export default NotesCard;




