import moment from "moment";
export const calculateAge = (dateStr) => {
  const birthYear = new Date(dateStr).getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;

  return age || 1;
};

export const changeDateFormat = (currentdate) => {
  let date = moment(currentdate);
  let formattedDate = date.format("DD/MM/YYYY");
  
  return formattedDate;
};

export const genericSuccessHandlerForSingleItem = (resource) => {
  return (res, setData) => {
    if (res.data.status === "success") {
      if (Array.isArray(res.data?.data[resource]) && res.data.data[resource].length) {
        setData(res.data.data[resource][0])
      } else throw new Error("not found")
    } else throw new Error("not found")
  }
}