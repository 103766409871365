import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import { PrivateRoutes, publicRoute  , redirectIfAuthenticatedRoutes, subscriptionRoute} from './route';
import { api } from '../../config/api'
import { removeToken, removeActiveUser } from '../../redux/auth/auth';
import { createCustomer, allSubscription, fetchPrices } from '../../redux/subscription/subscription';
import { Spinner } from 'react-bootstrap';
import { fetchMyClients } from '../../redux/myClients/myClients';
import logo from '../../assets/images/facicon.png';
const RedirectIfAuthenticated = ({ user, children }) => {
  if (user) {
    return <Navigate to="/p/my-clients" replace />
  }
  return children;
};
const ProviderRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { auth } = useSelector(state => state);
  const navigate = useNavigate();
  const { customer, currentSubscription } = useSelector(state => state.subscription);
  const date = useMemo(() => moment(currentSubscription?.currentPeriodEnd), [currentSubscription.currentPeriodEnd]);
  const now = useMemo(() => moment(), []);
  const dispatch = useDispatch()
  useEffect(() => {
    console.log(customer?.id)
    console.log(auth?.activeUser?.customerId)
    let customerIdd;
    if (auth?.activeUser?.customerId && auth?.activeUser?.customerId !== "null") {
      console.log("true")
      customerIdd = auth?.activeUser?.customerId
    } else {
      console.log("false")
      customerIdd = customer?.id
    }
    !!auth.token && customerIdd && dispatch(allSubscription({ customerId: customerIdd })).finally(() =>
      setIsLoading(false)

    );
  }, [auth.token, customer]);
  const checkSession = (id) => {
    api.post('/provider/checkession', { id: auth?.activeUser?._id }).then((res) => {
      if (res.data.status === "success" && res.data.data.length === 0) {
        logout()
      } else if (res.data.status === "failed") {
        logout()
      }
    }).catch((error) => {
      logout()
    })
  }

  useEffect(() => {
    if (!!auth.token) {
      if (!currentSubscription.currentPeriodEnd) {
        setIsLoading(true)
      }
      dispatch(createCustomer())
      dispatch(fetchMyClients());
      dispatch(fetchPrices());
      checkSession()
    } else {
      setIsLoading(false)
    }
  }, [auth.token])
  const logout = () => {
    localStorage.clear()
    dispatch(removeToken())
    dispatch(removeActiveUser())
    navigate('/login')
    window.location.reload()
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (location.pathname === '/') {
        navigate('/p/my-clients');
      }
    } else {
      if (location.pathname === '/') {
        navigate('/login');
      }
    }

  }, [location, navigate]);
  if (isLoading) {
    return <div className='iconimage'> <img src={logo} alt="" className='blink-image' style={{ width: 200}} /></div>
  
  } else {
    return (
      <>
        {!!auth.token && <NavBar />}
        <Routes>
          {publicRoute.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          {redirectIfAuthenticatedRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
            <RedirectIfAuthenticated user={!!auth.token}>
                {route.element}
            </RedirectIfAuthenticated>
                }
          />
          ))}
          {PrivateRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute user={!!auth.token} navigate={navigate} subscription={(currentSubscription &&(date < now || !currentSubscription?._id))}>
                  {route.element}
                </ProtectedRoute>
              }
            >
              {Array.isArray(route.childrens) && (route.childrens.map((nestedRoute) => <Route
              key={nestedRoute.path}
              path={nestedRoute.path}
              element={
                <ProtectedRoute user={!!auth.token} navigate={navigate} subscription={(currentSubscription &&(date < now || !currentSubscription?._id))}>
                  {nestedRoute.element}
                </ProtectedRoute>
              }
            >
            </Route>))}
            </Route>
          ))}
              {subscriptionRoute.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </>

    );
  }
};
const ProtectedRoute = ({ user, children, navigate, subscription }) => {
  if (!user) {
    return <Navigate to="/login" replace />
  } else if (subscription) {
    return <Navigate to="/p/choose-subscription" />
  }
  return children;
};

export default ProviderRoutes;
