import React from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import ConfirmDelete from "../../../assets/images/confirmRemove.gif";
import styles from "./RemoveClientModal.module.css";

const RemoveClientModal = (props) => {
    const propData = {...props}
    return (
        <Modal
            show={propData.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                    <Row className={'py-5'}>
                        <Col md={12} className='mb-2 text-center'>
                            <div className={''}>
                                <img src={ConfirmDelete} alt="" style={{maxWidth: 100}}/>
                            </div>
                            <p className={'fw-bold'}>
                                Are you sure you want to remove Sam Samson?
                            </p>
                        </Col>
                        <Col md={3} className='mb-2'/>
                        <Col md={6} className='mb-2 text-center'>
                            <button type='submit' className={styles.doNotRemoveBtn} onClick={propData.onHide}>
                                No, I’ve changed my mind
                            </button>
                            <button type='submit' className={styles.RemoveBtn} onClick={propData.onconfirm}>
                                Yes, remove
                            </button>
                        </Col>
                        <Col md={3} className='mb-2'/>
                    </Row>


            </Modal.Body>
        </Modal>
    );
};

export default RemoveClientModal;