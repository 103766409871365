import moment from 'moment';
import React from 'react';
import { calculateAge } from '../../config/utils';
import styles from './ClientData.module.css'
import providerImage from "../../assets/images/default.webp";

const ClientData = ({client}) => {
    return (
        
        <div className={'mb-5'}>
            <h4 className={'mb-3'}>Client’s information</h4>
            <div className="row">
                <div className="col-lg-6">
                    <div className="d-flex">

                        <img src={client.imageUri ?? providerImage} alt="" className={`${styles.clientProfileImage} me-3`} />
                        <div>

                            <h4 className={'mb-3'}>{client.firstName} {client.lastName}</h4>
                            <p className={'mb-2'}>Age: {calculateAge(client.dateOfBirth)} years</p>
                            <p className={'mb-2'}>Date of Birth: {moment(client.dateOfBirth).format('LL')}</p>
                            {/*<p className={'mb-1'}>Diagnosis: Autism</p>*/}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <p className={'mb-1'}>Phone: {client.phone}</p>
                    <p className={'mb-1'}>Email: {client.email}</p>
                </div>

            </div>
        </div>
    );
};

export default ClientData;