import { configureStore } from "@reduxjs/toolkit";
import authSlicer from "./auth/auth";
import chatSlicer from "./chat/chat";
import myClientsSlicer from "./myClients/myClients";
import clientProgramSlicer from "./uploadFiles/program";
import mySubscriptionSlicer from "./subscription/subscription";
import Providerlicer from "./provider/provider";
export default configureStore({
  reducer: {
    auth: authSlicer,
    myClients: myClientsSlicer,
    clientPrograms: clientProgramSlicer,
    chat: chatSlicer,
    subscription: mySubscriptionSlicer,
    connectedProviders: Providerlicer,
  },
});
