import React, { useState } from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import styles from "./FeedbackModal.module.css";
import { FaTimes } from "react-icons/fa";
import moment from 'moment/moment';

const FeedbackModal = (props) => {
    const propData = { ...props }
    const [note, setNote] = useState('')

    const Submit = () => {
        propData.addNote(note)
        setNote('')

    }
    return (
        <Modal
            show={propData.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className={'position-relative'}>
                    <p className={`position-absolute ${styles.crossBtn}`} onClick={propData.onHide}><FaTimes /></p>

                    <Row className={'py-5'}>
                        <Col md={12} className='mb-2'>
                            {propData.notes.map((value, index) => {
                                return <React.Fragment key={index}>
                                    
                                    <h6>{moment(value.createdAt).format('DD/MM/YYYY h:mm:ss a')}</h6>
                                    <div className={value.writer == propData.id ? styles.ownnoteDetail : styles.noteDetail}>
                                    <h6> Author : {value.author}</h6>
                                    <p >
                                       
                                        {value.note}
                                    </p>
                                    </div>
                                </React.Fragment>
                            })}

                            <textarea name="" id="" cols="30" value={note} rows="10" className={`form-control ${styles.customTextArea}`} onChange={((e) => setNote(e.target.value))}></textarea>
                            <div className={'d-flex justify-content-between'}>
                                <button type='submit' className={styles.cancelBtn} onClick={propData.onHide}>
                                    Cancel
                                </button>
                                <button type='submit' className={styles.submitBtn} onClick={Submit}>
                                    Submit
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>


            </Modal.Body>
        </Modal>
    );
};

export default FeedbackModal;