import React from 'react';
import styles from './ChooseSubscriptionCard.module.css'
import moment from 'moment';
const InvoiceCard = ({ currentSubscription  , portalSession}) => {
    return (
        <div className="product mt-5">
            <div
                className={`card h-100 p-3 position-relative ${styles.premium}`} style={{width: "591px"}}>
                <span className={styles.prices}>
                    <p className={'py-3 fs-6'}>  Status: {currentSubscription.status}.</p>
                    <p className={'py-3 fs-6'}>  Start date : {moment(currentSubscription.currentPeriodStart).format('DD/MM/YYYY h:mm:ss a')}</p>
                    <p className={'py-3 fs-6'}>  End date : {moment(currentSubscription.currentPeriodEnd).format('DD/MM/YYYY h:mm:ss a')}</p>
                </span>
                <div className={'py-5'} />
                <div className={`w-100 text-center px-3  ${styles.buttonContainer}`} onClick={() => portalSession()}>
                    <button className={'btn btn-lg w-100 my-3'}> Manage your billing information</button>
                </div>
            </div>
        </div>
    );
};

export default InvoiceCard;



