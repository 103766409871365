import React from 'react';
import { genericSuccessHandlerForSingleItem } from '../../config/utils';
import { useGet } from '../../hooks/api/shared/generalEndpoint';
import ConditionalSpinner from '../../components/shared/ConditionalSpinner/ConditionalSpinner';
import { useParams } from 'react-router-dom';
const ResourceDetail = () => {
    const { resourceId } = useParams()
    const {data: fetchedResource, loading, isError} = useGet(`/provider/resource/${resourceId}`, genericSuccessHandlerForSingleItem('resource'))
    return (
        <ConditionalSpinner loading={loading}>
        {(!isError && !loading) && <div className="col-lg-9">
        <div className="p-3">
            <h4>{fetchedResource.fileName}</h4>
            {(fetchedResource.fileName.split('.').reverse()[0] == 'doc' || fetchedResource.fileName.split('.').reverse()[0] == 'docx') ? <iframe title={fetchedResource.fileName} src={`https://docs.google.com/gview?url=${fetchedResource.filePath}&embedded=true`} width="100%" height="500px" /> :
                <iframe title={fetchedResource.fileName} src={fetchedResource.filePath} width="100%" height="500px" />}
        </div>
    </div>}
    </ConditionalSpinner>
    );
};

export default ResourceDetail;