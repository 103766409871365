import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import pdfImage from '../../assets/images/pdf.png';
import { changeDateFormat } from '../../config/utils';
import styles from './ClientUploads.module.css';
const ClientUploads = ({ clientUpload }) => {
    const [selectedupload, setSelectedUpload] = useState(null);
    const [show, setShow] = useState(false);

    function handleShow(upload) {
        setSelectedUpload(upload)
        setShow(true);
    }
    return (
        <div>
            <h4 className={'mb-3'}>Client’s uploads</h4>
            {!clientUpload && <h6 style={{ color: "#468189" }}>No contents uploaded yet!</h6>}
            <>
                {clientUpload && clientUpload.map((v, i) =>
                    <div key={i}>
                        <p className={'pt-3'}>{changeDateFormat(v.date)}</p>
                        <div className="d-flex flex-wrap">
                            {v.data.map((v, i) =>
                                <React.Fragment key={i}>
                                    <div onClick={() => handleShow(v)} style={{ cursor: "pointer" }}>
                                        {v.clientUploadFilePath.split('.').reverse()[0] === "pdf" ? <><img src={pdfImage} alt="" className={`${styles.uploadsImage} me-3`} />
                                            <p className={'fw-bold'}>{v.clientUploadFileName?.substring(0, 10)}..</p></> : <> <img src={v.clientUploadFilePath} alt="" className={`${styles.uploadsImage} me-3`} />
                                            <p className={'fw-bold'}>{v.clientUploadFileName.substring(0, 12)}...</p></>
                                        }

                                    </div>

                                    <div>

                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </>
            {selectedupload && <Modal show={show} fullscreen={true}  onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedupload.clientUploadFileName}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display : "flex"}}>
                    <iframe title={selectedupload.clientUploadFileName} src={selectedupload.clientUploadFilePath} width="100%" height="100%"  style={{textAlign : "center" , width :  "100vw !important"}}/>
                </Modal.Body>
            </Modal>}
        </div>
    );
};

export default ClientUploads;