import React, {useState} from 'react';
import styles from './InviteProvider.module.css'
import ClientNav from "../../components/shared/ClientNav/ClientNav";
import {AiOutlineRight, AiOutlineSearch} from "react-icons/ai";
import InviteProviderSuccessModal from "../../components/modals/InviteProviderSuccessModal";

const InviteProvider = () => {
    const [inviteModalShow, setInviteModalShow] =useState(false)

    return (
        <div className={'bg-light'} style={{height: "100vh"}}>
            <div className={'container pt-5'}>
                <h5 className={'pt-5'}>Clients
                    <span><AiOutlineRight/> Client’s info</span>
                    <span className={'activeBreadCrumb'}><AiOutlineRight/> Invite Provider</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container" style={{height: "80vh"}}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div style={{position: "sticky", top: 0}}>
                                <ClientNav/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="p-3">
                                <h4>Invite Provider</h4>
                                <div className={'d-flex'}>
                                    <div className="input-group flex-nowrap me-xl-2 me-lg-0">
                                    <span className={`input-group-text ${styles.adornment}`}
                                          id="addon-wrapping"><AiOutlineSearch/></span>
                                        <input type="text" className={`form-control ${styles.searchInput}`}
                                               placeholder="Search provider with email" aria-label="search"
                                               aria-describedby="addon-wrapping"/>
                                    </div>
                                    <button className="btn btn-dark px-4" onClick={()=>setInviteModalShow(true)}>Invite</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InviteProviderSuccessModal
                show={inviteModalShow}
                onHide={() => {
                    setInviteModalShow(false)
                }}
            />
        </div>
    );
};

export default InviteProvider;