import React, { useState, Suspense, memo, useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from '../../../config/api';
import styles from './ClientNav.module.css';
import { useDispatch } from 'react-redux';
import { getAllConnectedProviders } from '../../../redux/provider/provider';
import { FcLock } from 'react-icons/fc'
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { fetchlinkedProvider } from '../../../redux/myClients/myClients';
// import { setClientId } from '../../../redux/myClients/myClients';
const RemoveClientModal = React.lazy(() => import("../../modals/RemoveClientModal/RemoveClientModal"));
const RequestAccessSuccessModal = React.lazy(() => import("../../modals/RequestAccessSuccessModal"));
const ClientNav = memo(() => {
    const { clientId } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [requestAccessModalShow, setRequestAccessModalShow] = useState(false)
    const { linkedProvider: linkProvider } = useSelector(state => state.myClients)
    const { conectedProviders } = useSelector(state => state.connectedProviders)
    const { pathname } = useLocation();
    const { myClientById } = useSelector(state => state.myClients)
    const { activeUser } = useSelector(state => state.auth);
    const [connectedProv, disConnectedProv] = useFilteredArrays(conectedProviders, linkProvider);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const removeClient = () => {
        api.delete(`/provider/removeClient/${clientId}`).then(() => {
            navigate('/')
        })
    }
    useEffect(() => {
        dispatch(getAllConnectedProviders(activeUser._id))
        dispatch(fetchlinkedProvider(clientId))
        // if (!clientId) {
        //     navigate('/p/my-clients')
            // if (!storedClientId) dispatch(setClientId(clientId))
            // else if(storedClientId != clientId) navigate('/p/my-clients')
        // }
    }, [])
    const sendRequesttoProvider = async (reciverProvider) => {
        const providerInfo = {
            senderProviderId: activeUser._id,
            receiverProviderId: reciverProvider._id,
            shareClientId: clientId
        }
        Swal.fire({
            title: '',
            text: "Your client has not yet linked you to this provider. Please send a request below to let your client know you would like to collaborate with this provider",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Request'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post(`/provider/sendRequesttoProviders`, providerInfo).then((res) => {
                    setRequestAccessModalShow(true)
                    dispatch(getAllConnectedProviders(activeUser._id))
                })

            }
        })
    }

    function useFilteredArrays(firstArray, secondArray) {
        const connectedProv = useMemo(() => {
            if (!firstArray || !secondArray) {
                return []
            }
            return secondArray.filter((provider) => {
                return firstArray.some((conversation) => {
                    return ((conversation.connectedProviders._id === provider.providerId._id || conversation.senderProvider._id === provider.providerId._id) && conversation.shareClient._id === clientId);
                });
            });

        }, [firstArray, secondArray]);

        const disConnectedProv = useMemo(() => {
            if (!firstArray || !secondArray) {
                return []
            }
            return secondArray.filter((provider) => {
                return !firstArray.some((conversation) => {
                    return ((conversation.connectedProviders._id === provider.providerId._id || conversation.senderProvider._id === provider.providerId._id) && conversation.shareClient._id === clientId);
                });
            });

        }, [firstArray, secondArray]);

        return [connectedProv, disConnectedProv];
    }
    return (
        <div className="shadow-sm">
            <div className={'pt-4'}>
                <Link to={`/p/client/client-info/${clientId}`} state = {{client: myClientById}}
                    className={`${styles.navItem} ${pathname.split('/')[3] === 'client-info' && styles.active}`}>Client’s
                    info</Link>
                <Link to={`/p/client/my-programs/${clientId}`}
                    className={`${styles.navItem} ${pathname.split('/')[3] === 'my-programs' && styles.active}`}>My
                    Programs</Link>
                <Link to={`/p/client/my-reports/${clientId}`}
                    className={`${styles.navItem} ${pathname.split('/')[3] === 'my-reports' && styles.active}`}>My
                    Reports</Link>
                <Link to={`/p/client/my-resources/${clientId}`}
                    className={`${styles.navItem} ${pathname.split('/')[3] === 'my-resources' && styles.active}`}>My
                    Resources</Link>
                {/*<Link to={'/p/linked-providers'}
                      className={`${styles.navItem} ${pathname.split('/')[2] === 'linked-providers' && styles.active}`}>Linked
                    Providers</Link>*/}
                <hr className={'m-0'} />
                <Accordion>
                    <Accordion.Item eventKey="0" className={styles.accordionItem}>
                        <Accordion.Header><span
                            className={styles.LinkedProviderAccordion}>Linked Providers</span></Accordion.Header>
                        <Accordion.Body className={'p-0'}>
                            {connectedProv && connectedProv.map((el, i) => {
                                return <React.Fragment key={i}>
                                    {el.providerId && activeUser._id !== el.providerId._id &&
                                        <Link key={i} className={`${styles.LinkedProviderListItem} p-3 m-0`}
                                            to={`/p/client/linked-providers/${clientId}/provider-info/${el.providerId._id}`}>{el.providerId.firstName + " " + el.providerId.lastName}</Link>}
                                </React.Fragment>
                            }
                            )
                            }
                            {disConnectedProv && disConnectedProv.map((el, i) => {
                                return <React.Fragment key={i}>
                                    {el.providerId && activeUser._id !== el.providerId._id &&
                                        <>  <span onClick={() => sendRequesttoProvider(el.providerId)} key={i} className={`${styles.LinkedProviderListItem} p-3 m-0`}>{el.providerId.firstName + " " + el.providerId.lastName} <FcLock /></span></>}
                                </React.Fragment>
                            }
                            )
                            }

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* <Link to={'/p/invite-providers'}
                    className={`${styles.navItem} ${pathname.split('/')[2] === 'invite-providers' && styles.active}`}>Invite
                    Providers</Link> */}

            </div>
            <div className={'py-5'} />
            <p className={`${styles.removeClient} mx-3 px-3 py-2`} onClick={() => setModalShow(true)}>Remove Client</p>
            <br />
            <Suspense fallback={<div>Loading...</div>}>
                <RemoveClientModal
                    show={modalShow}
                    onconfirm={() => removeClient()}
                    onHide={() => {
                        setModalShow(false)
                    }}
                />
                <RequestAccessSuccessModal
                    show={requestAccessModalShow}
                    name={myClientById?.firstName}
                    onHide={() => {
                        setRequestAccessModalShow(false)
                    }}
                />
            </Suspense>
        </div>
    );
})

export default ClientNav;