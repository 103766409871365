import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../config/api";
import { fetchMyClients, getMyClientById } from "../../redux/myClients/myClients";
import styles from "./MyClients.module.css";
const MyClients = () => {
  const dispatch = useDispatch();
  const { myClients } = useSelector((state) => state.myClients);
  const { customer, currentSubscription } = useSelector(
    (state) => state.subscription
  );

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const deleteClient = useCallback(
    async (clientId) => {
      try {
        const result = await Swal.fire({
          title: "Do you want to delete?",
          showCancelButton: true,
          confirmButtonText: "Delete",
        });
        if (!result.isConfirmed) return;
        const res = await api.delete(`/provider/removeClient/${clientId}`);
        if (res.data.status === "success") {
          dispatch(fetchMyClients());
          await Swal.fire("Successfully deleted!", "", "success");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const ClientDetail = useCallback(
    (client) => {
      const date = moment(currentSubscription.currentPeriodEnd);
      const now = moment();
      if (date > now) {
        dispatch(getMyClientById(client));
        navigate(`/p/client/client-info/${client._id}`);
      }
    },
    [currentSubscription.currentPeriodEnd, navigate]
  );

  const date = useMemo(
    () => moment(currentSubscription.currentPeriodEnd),
    [currentSubscription.currentPeriodEnd]
  );
  const now = useMemo(() => moment(), []);

  useEffect(() => {
    // dispatch(createCustomer());
    myClients.length == 0 && dispatch(fetchMyClients());
  }, [dispatch]);

  // useEffect(() => {
  //   if (customer) {
  //     dispatch(allSubscription({ customerId: customer.id })).finally(() =>
  //       setIsLoading(false)
  //     );
  //   }
  // }, [customer, dispatch]);

  // if ((date < now || !currentSubscription._id) && !isLoading) {
  //   return navigate("/p/choose-subscription");
  // }

  return (
    <div className={"bg-light"} style={{ height: "100vh" }}>
      <div className={"container pt-5"} style={{maxWidth :  "90vw"}}>
        <h4 className={"py-5"}>My Clients</h4>
        <div
          className="card border-0 page-container"
          style={{ height: "80vh" }}
        >
          <div className="table-responsive">
            <div className={styles.tableContainer}>
              {isLoading && (
                <Spinner
                  style={{
                    position: "absolute",
                    right: "52%",
                    zIndex: 999,
                    top: "251px",
                  }}
                />
              )}
              <table className={`table`}>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th className={"p-3"}>Name</th>
                    <th className={"p-3"}>Age</th>
                    <th className={"p-3"}>Linked Providers</th>
                    <th className={"p-3"}>Actions</th>
                  </tr>
                </thead>

                <tbody className={"h-100 w-100"}>
                  {!isLoading && (
                    <>
                      {myClients && myClients?.length > 0 ? (
                        myClients.map((val, index) =>(
                          // client.clients.map((val, index) => (
                            <tr key={index}>
                              <td
                                className={"p-3"}
                                style={{ cursor: "pointer" }}
                                onClick={() => ClientDetail(val.client)}
                              >
                                {val.client.firstName} {val.client.lastName}
                              </td>
                              <td className={"p-3"}>
                                {moment().diff(val.client.dateOfBirth, "years")} years
                                old
                              </td>
                              <td className={"p-3"}>
                                {val.name.slice(0, 3).map((el, i) => (
                                  <span
                                    className={styles.providerBadge}
                                    key={i}
                                  >
                                    {el}
                                  </span>
                                ))}
                                {val?.name?.length > 3 && (
                                  <span className={styles.providerBadge}>
                                    {val?.name?.length - 3} More
                                  </span>
                                )}
                              </td>
                              <td className={"p-3"}>
                                <FaTrashAlt
                                  onClick={() => deleteClient(val.client._id)}
                                  className={styles.deleteIcon}
                                />
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr className={"h-100"}>
                          <td colSpan={"4"} className={"h-100"}>
                            <div
                              className={
                                "h-100 w-100 d-flex justify-content-center align-items-center"
                              }
                            >
                              <h5 className={"text-center"}>
                                No data to show. Please invite your client with
                                their email address. When they accept your
                                invitation, their information will appear here.
                              </h5>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyClients;
