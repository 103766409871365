import React from 'react';
import ClientData from "../../components/ClientData/ClientData";
import ClientUploads from "../../components/ClientUploads/ClientUploads";
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getMyClientById, fetchlinkedProvider } from '../../redux/myClients/myClients';
import { api } from '../../config/api';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useCallback } from 'react';
import { fetchResource , fetchReports , fetchProgram } from '../../redux/provider/provider';
const ClientInfo = () => {
    const { clientId } = useParams();
    const { activeUser } = useSelector(state => state.auth);
    const [client, setClents] = useState(null)
    const [clientbyUpload, setUploads] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getClient = useCallback(v => {
        api.get(`provider/clientDetails/${clientId}`).then((res) => {
            dispatch(getMyClientById(res.data.data))
            setClents(res.data.data)
        }).catch(err => {
            console.log(err)
            return navigate('/p/my-clients')
        })
    }
    )
    const clientUpload = () => {
        api.get(`/provider/clientUploads/${clientId}`).then((res) => setUploads(res.data.data)).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getClient()
        clientUpload()
        dispatch(fetchResource(clientId))
        dispatch(fetchReports(clientId))
        dispatch(fetchProgram(clientId))
    }, [clientId , activeUser._id]);

    return ( client ? <div className="col-lg-9">
                            <div className="p-3">
                                <ClientData client={client} />
                                <ClientUploads clientUpload={clientbyUpload} />
                            </div>
                        </div> : <Spinner style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            margin: " 10% auto"

                        }}></Spinner>)
};

export default ClientInfo;