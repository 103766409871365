import React from 'react';
import pdfImage from "../../assets/images/pdf.png";
import { FaTimesCircle } from "react-icons/fa";
import { useEffect } from 'react';
import { useState } from 'react';

const Preview = ({ meta, files }) => {
    const { id, name, size, type, status } = meta
    const [percent, setPercent] = useState(0)
    useEffect(() => {
        if (status === 'done') {
            setPercent(100)
        }
        if(status == 'preparing'){
            setPercent(50)
        }
    }, [status])

    return (
        <div className={'my-2 w-100'} style={{ display: "grid", gridTemplateColumns: "1fr 9fr", columnGap: 15 }}>
            <div style={{ height: 100, width: 100 }}>
                {
                    type === "application/pdf" ?
                        <img src={pdfImage} alt="" style={{ height: 80, width: 80, objectFit: "cover" }} />
                        :
                        <img src={pdfImage} alt="" style={{ height: 80, width: 80, objectFit: "cover" }} />
                }
            </div>
            <div>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div>
                        <h6>{name}</h6>
                        <p>
                            {Math.floor(size / 1024)}KB
                        </p>

                    </div>
                    <p onClick={() => (files.find(file => file.meta.id === id)).remove()}
                        className={'text-danger fs-5'}><FaTimesCircle /></p>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "9fr 1fr", columnGap: 15 }}>
                    <div className={'position-relative'} style={{ height: 10 }}>
                        <div style={{ width: "100%", height: 10, backgroundColor: "#F5F5F5", borderRadius: 22 }} />
                        <div style={{
                            width: `${Math.round(percent)}%`,
                            height: 10,
                            backgroundColor: "#77ACA2",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                            transition: "all 0.3s",
                            borderRadius: 22
                        }} />
                    </div>
                    <p style={{ marginTop: -8 }}>{Math.round(percent)}%</p>
                </div>

            </div>
        </div>
    );
};

export default Preview;