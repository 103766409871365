import React from 'react';

const Layout = ({input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {
    return (
        <div>

            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>
            <br/><br/>
            {previews}
            {files.length > 0 && submitButton}
        </div>
    );
};

export default Layout;