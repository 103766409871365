import { createSlice } from "@reduxjs/toolkit";
export const authSlicer = createSlice({
    name: 'auth',

    initialState: {
        activeUser: localStorage.getItem('activeUser') !== 'undefined' ? JSON.parse(localStorage.getItem('activeUser')) : null,
        token: localStorage.getItem('token') ?? null,
    },

    reducers: {
        setActiveUser: (state, { payload }) => {
            state.activeUser = payload;
            localStorage.setItem('activeUser', JSON.stringify(state.activeUser));
        },

        removeActiveUser: (state) => {
            state.activeUser = null;
            localStorage.removeItem('activeUser');
        },

        setToken: (state, { payload }) => {
            state.token = payload;
            localStorage.setItem('token', state.token);
        },

        removeToken: (state) => {
            state.token = null
            localStorage.removeItem('token');
        },
     
    }
})

export const { setActiveUser, removeActiveUser, setToken, removeToken } = authSlicer.actions;

export default authSlicer.reducer;