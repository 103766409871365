import { useEffect, useState } from "react"
import { api } from "../../../config/api";
import { toast } from "react-toastify";
function defaultErrorCallback(error, setIsError){
    console.log(error);
    toast.error("Some thing went wrong")
    setIsError(true)
}
function defaultSuccessCallback(res, setData) {
    if (res.data.status === "success") {
      if (typeof res.data?.data == 'object') {
        setData(res.data.data)
      } else throw new Error("Failed, try again")
    } else throw new Error("Failed, try again")
  }
export const useGet = (url, successCallback = defaultSuccessCallback, initialValue = null, initialLoadingState = true, errorCallback = defaultErrorCallback) => {
    const [data, setData] = useState(initialValue)
    const [isError, setIsError] = useState(false)
    const [loading, setLoading] = useState(initialLoadingState);
    useEffect(() => {
        api.get(url).then((res) => successCallback(res, setData)).catch((error) => errorCallback(error, setIsError)).finally(() => {
          setLoading(false)
        })
    }, [])
    return {data, loading, setData, isError}
}