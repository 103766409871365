import React from 'react';
import FileUploader from "../../components/FileUploader/FileUploader";
import { useParams } from 'react-router-dom';

const CreateReports = () => {
    const { clientId } = useParams();
    return (
        <div className="col-lg-9">
        <div className="p-3">
            <h4>Reports</h4>
            <FileUploader accepts={'.pdf , .doc'} path={'/provider/report'} nav={`/p/client/my-reports/${clientId}`} type={"report"}/>
        </div>
    </div>
    );
};

export default CreateReports;