import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../../assets/images/logo.png";
import { api } from "../../../config/api";
import { setActiveUser, setToken } from "../../../redux/auth/auth";
import Toast from "../../Notification/toast";
import styles from "./Login.module.css";
import CustSpinner from "../../Spinner/CustSpinner";
const Login = () => {
  const [show, setshow] = useState(false);
  const [vrfiycreen, setVerfiyscrenn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [token, settoken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setIsLoading(true);
    if (Object.keys(data).length) {
      api
        .post("/provider/login", data)
        .then((response) => {
          if (response.data.status === "success") {
            setUserId(response.data.data.user._id);
            setIsLoading(false);
            // dispatch(setActiveUser(response.data.data.user));
            // dispatch(setToken(response.data.token));
            // navigate(location?.state?.from || '/p/my-clients');
            Toast("Code has been sent to your email", "success");
            setVerfiyscrenn(true);
          } else if (response.data.status === "failed") {
            Toast(response.data.message, "warning");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          Toast(err.response?.data.message, "error");
          console.log(err.response);
          setIsLoading(false);
        });
    }
  };

  const verfiyUser = (e) => {
    setIsLoading(true);
    e.preventDefault();
    token &&
      api
        .get(`/provider/verify?_userId=${userId}&token=${token}`)
        .then((response) => {
          setIsLoading(false);
          if (response.data.status === "success") {
            dispatch(setActiveUser(response.data.user));
            dispatch(setToken(response.data.token));
            localStorage.setItem("loginTime", new Date().getTime());
            navigate("/p/my-clients");
            Toast("Successfully logged in", "success");
            settoken("");
            setVerfiyscrenn(false);
          } else if (response.data.status === "fail") {
            Toast(response.data.message, "warning");
          }
        })
        .catch((err) =>{ 
          Toast(err.response.data.message, "error")
          setIsLoading(false);
        });
  };

  const resendVerficationCode = () => {
    api.get(`/provider/resendActivationCode?_id=${userId}`).then((res) => {
      Toast("Code has been resent to your email", "success");
    });
  };
  useEffect(() => {
    setVerfiyscrenn(false);
    if (!show) {
      return;
    }
    // detected rendering
  }, [show]);
  const showpassword = (id) => {
    setshow(!show);
    // const element = document.getElementById(id);
    // element.type = show ? "password" : "text";
  };

  return (
    <Container fluid>
      <Row className="px-0 d-md-block">
        {/* <Col md={6} className={'px-0 d-none d-md-block'} >
                    <img className={styles.LoginImage} src={auth_bg} alt="" />
                </Col> */}
        <Col md={12} className={`my-auto ${styles.authcontainer}`}>
          <div>
            <div className="text-center ">
              <img className={styles.logo} src={Logo} alt="" />
            </div>
            <h1 className={`${styles.title} mt-5`}>Sign in to your account </h1>
            {/*<p className={styles.typography}>Fill Out The Form</p>*/}
            <Container>
              <form
                action=""
                onSubmit={vrfiycreen ? verfiyUser : handleSubmit(onSubmit)}
              >
                {!vrfiycreen ? (
                  <>
                    {" "}
                    <Row>
                      <Col md={3} className="mb-2" />
                      <Col md={6} className="mb-2">
                        <input
                          className={styles.input}
                          type="text"
                          placeholder="Username or Email"
                          {...register("userName", { required: true })}
                        />

                        <span
                          className={`${errors.userName ? styles.visible : ""
                            } ${!errors.userName ? styles.error : ""}`}
                        >
                          This field is required
                        </span>
                      </Col>
                      <Col md={3} className="mb-2" />
                    </Row>
                    <Row>
                      <Col md={3} className="mb-2" />
                      <Col md={6} className="mb-2 position-relative">
                        <div className="position-relative">
                        <input
                          className={`${styles.input} ${styles.passwordInputPadding}`}
                          type={show ? "text" : "password"}
                          id="password"
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                        {show ? (
                          <i
                            onClick={() => showpassword("password")}
                            className={styles.pass}
                          >
                            <FaEye />
                          </i>
                        ) : (
                          <i
                            onClick={() => showpassword("password")}
                            className={styles.pass}
                          >
                            <FaEyeSlash />
                          </i>
                        )}
                        </div>
                        <span
                          className={`${errors.password ? styles.visible : ""
                            } ${!errors.password ? styles.error : ""}`}
                        >
                          This field is required
                        </span>
                      </Col>
                      <Col md={3} className="mb-2" />
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col md={3} className="mb-2" />
                    <Col md={6} className="mb-2">
                      <input
                        className={styles.input}
                        type="text"
                        placeholder="Verification code"
                        {...register("userId", { required: true })}
                        value={token}
                        onChange={(e) => settoken(e.target.value)}
                      />
                      {/* <span className={`${errors.userId ? styles.visible : ""} ${!errors.userId ? styles.error : ""}`} >This field is required</span> */}
                    </Col>
                    <Col md={3} className="mb-2" />
                  </Row>
                )}
                <Row>
                  <Col md={3} className="mb-2" />
                  <Col md={6} className="mb-2">
                    {!vrfiycreen && (
                      <p>
                        <Link
                          to={"/forgot-password"}
                          className={"text-decoration-none"}
                          style={{ color: "#468189" }}
                        >
                          Reset password
                        </Link>
                      </p>
                    )}
                    {vrfiycreen && (
                      <p>
                        <p
                          onClick={resendVerficationCode}
                          className={"text-decoration-none"}
                          style={{ color: "#468189", cursor: "pointer" }}
                        >
                          Resend verification code
                        </p>
                      </p>
                    )}
                    <div className="text-center">
                      <button
                        type="submit"
                        className={styles.LoginBtn}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <CustSpinner />
                        ) : vrfiycreen ? (
                          "verify"
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                    {!vrfiycreen && (
                      <p
                        className={"text-center text-dark mt-3 mb-2"}
                        onClick={() => navigate("/register")}
                      >
                        Create account{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#468189",
                            textDecoration: "underline",
                          }}
                        >
                          here
                        </span>
                        !
                      </p>
                    )}
                    {vrfiycreen && (
                      <p>
                        <p
                          className={
                            "text-decoration-none text-center text-dark mt-5"
                          }
                          style={{ color: "#468189" }}
                        >
                          Having trouble receiving code?{" "}
                          <a
                            target="_blank"
                            href="mailto:queries@linkmehealth.com.au"
                          >
                            Write to Us
                          </a>
                        </p>
                      </p>
                    )}
                  </Col>
                  <Col md={3} className="mb-2" />
                </Row>
              </form>
              <ToastContainer />
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
