import React from 'react';
import styles from './ProgramCard.module.css'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineArrowRight } from "react-icons/ai";
import { changeDateFormat } from '../../config/utils';
import { useDispatch } from 'react-redux';
import { fetchProgram } from '../../redux/provider/provider';
import Toast from '../Notification/toast';
import { MdDelete } from 'react-icons/md';
import { api } from '../../config/api';
const ProgramCard = ({ program , access}) => {
    const dispatch = useDispatch()
    const { clientId } = useParams()
    const deleteProgram = (id) => {
        api.get(`/provider/deleteProgram/${id}`).then((res) => {
            if (res.data.status === "success") {
                dispatch(fetchProgram(clientId))
                Toast("Successfully deleted" , "success")
            }
        })
    }
    return (
        <div className={'my-4'}>
            {Object.entries(program).map(([key, value]) =>
                <>
                    <h6 className={styles.date}>{changeDateFormat(key)}</h6>
                    {value.map((val , index) => <div className={'row g-4'} key={index}>       
                    { val.program.map((v, i) =>
                        <div key={i} className={`${access ? 'col-lg-12 border-radius-th' : 'col-lg-6'}  p-2 ${styles.cardRes}`} >
                            <Link to={`/p/client/my-programs/program-detail/${clientId}/${val._id}`}
                                className={`${access ? styles.borderradiusth : ''} shadow rounded-left ${styles.customProgramCard}`}>
                                <h5 className={'py-1 m-0 w-100'}>{v.fileName.replace(/_/g, ' ')}</h5>
                                <AiOutlineArrowRight className={'fs-4'} />
                            </Link>
                            {!access &&<div className={`rounded-right ${styles.deleteIcon}`}>
                                 <MdDelete size={30} color='white' cursor="pointer" onClick={() => deleteProgram(val._id)} />
                             </div>}
                        </div>
                    )} 
                        
                         </div>)}

                </>

            )}
        </div>
    );
};

export default ProgramCard;