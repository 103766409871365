import React, { useEffect } from 'react';
import styles from './MyResources.module.css'
import { useDispatch, useSelector } from 'react-redux';
import ResourceCard from '../../components/ResourceCard/ResourceCard';
import { fetchResource } from '../../redux/provider/provider';
import { Link, useParams } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
const MyResources = () => {
    // const navigate = useNavigate()
    const dispatch =  useDispatch()
    const { clientId } = useParams();
    // const storedClientId = useSelector(state => state.myClients.clientId)
    // const { _id } = useSelector(state => state.myClients.myClientById)
    const { myResources } = useSelector(state => state.connectedProviders)
    // const fetchResorces = () => {
    //     api.get(`/provider/resourceForClient/${_id}`).then(
    //         (res) => setMyResources(res.data)
    //     )
    // }
    useEffect(() => {
        if(clientId) dispatch(fetchResource(clientId))
    }, [clientId])
    return (
        <div className="col-lg-9">
        <Link className={`position-absolute ${styles.addButton}`} to={`/p/client/my-resources/create-resources/${clientId}`}><BsPlusLg/></Link>
        <div className="p-3">
            <h4>Resources</h4>
            {myResources.data  && <ResourceCard reports={myResources.data} clientId={clientId} />}
        </div>
    </div>
    );
};

export default MyResources;