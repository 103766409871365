import React from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import successfully_sent_mail from "../../assets/images/invite-members.gif";
import styles from "../auth/forgotPassword/ForgotPassword.module.css";

const SuccessModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row className={'py-5'}>
                    <Col md={3} className='mb-2'/>
                    <Col md={6} className='mb-2 text-center'>
                        <div className={''}>
                            <img src={successfully_sent_mail} alt="" style={{maxWidth: 200}}/>
                        </div>
                        <p>
                            Your invitation to has been to sent to Goe Marsh!
                        </p>
                        <p>Once Goe Marsh accepts invitation, they need to register with Link Me.</p>
                        <button type='submit' className={styles.LoginBtn} onClick={props.onHide}>
                            Ok
                        </button>
                    </Col>
                    <Col md={3} className='mb-2'/>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;