import React from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import successfully_requested_access from "../../assets/images/requestAccess.gif";
import styles from "../auth/forgotPassword/ForgotPassword.module.css";

const SuccessModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row className={'py-5'}>
                    <Col md={3} className='mb-2'/>
                    <Col md={6} className='mb-2 text-center'>
                        <div className={''}>
                            <img src={successfully_requested_access} alt="" style={{maxWidth: 300}}/>
                        </div>
                        <p>Your request has been sent to {props.name}.
                            Once you have been granted access you will be notified.</p>
                        <button type='submit' className={styles.LoginBtn} onClick={props.onHide}>
                            Ok
                        </button>
                    </Col>
                    <Col md={3} className='mb-2'/>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;