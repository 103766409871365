import React from "react";
import styles from "./ChooseSubscriptionCard.module.css";
const ChooseSubscriptionCard = ({ cardType, price, createSubscription }) => {
  return (
    <>
      <div className={`card h-100 p-5 position-relative ${styles.standard}`}>
        <p className={styles.cardName}>{price.product.name}</p>
        <p>
          <span className={styles.price}>${price.unit_amount / 100}</span>
          <br />/{price?.recurring?.interval}
        </p>

        {cardType === "standard" ? (
          <p className={"py-3 fs-6"}>
            {" "}
            {price.nickname ??
              "Perfect for you to explore Link Me Digital Health. Yearly billing from you credit card"}{" "}
          </p>
        ) : (
          <p className={"py-3 fs-6"}>Save up to 16% with yearly billing.</p>
        )}
        <div className={"py-5"} />
        <div className={`w-100 text-center px-3 ${styles.buttonContainer}`}>
          <button
            className={"btn btn-lg w-90 my-3"}
            onClick={() => createSubscription(price)}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default ChooseSubscriptionCard;
