import React from 'react';
import {AiOutlineRight} from "react-icons/ai";
import SettingsNav from "../../components/shared/SettingsNav/SettingsNav";
import {Accordion} from "react-bootstrap";

const HelpAndSupport = () => {


    return (
        <div className={'bg-light'} style={{height: "100vh"}}>
            <div className={'container pt-5'}>
                <h5 className={'pt-5'}>Settings <span className={'activeBreadCrumb'}><AiOutlineRight/> Help and supports</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container" style={{height: "80vh"}}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div style={{position: "sticky", top: 0}}>
                                <SettingsNav/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="p-3">
                                <h4>Help and supports</h4>
                                <br/>
                                <div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>How to remove my cilent?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>How to request my client for other provider?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpAndSupport;