import React from "react";
import ClientNav from "../../components/shared/ClientNav/ClientNav";
import { AiOutlineRight } from "react-icons/ai";
import { Outlet, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { clientlayoutChildRoutesMapping } from "../../config/constants";
import { ToastContainer } from "react-toastify";
export default function ClientLayout () {
    const location = useLocation();
    const breadCrumbs = useMemo(() => {
        const found = clientlayoutChildRoutesMapping.find(routeMapping => location.pathname.includes(routeMapping.identifier))
        if (found) return found.data
        else return []
    }, [location.pathname])
  return (
    <div className={"bg-light"} style={{ height: "100vh" }}>
       <ToastContainer autoClose={false} draggable={false} />
      <div className={"container pt-5"}>
        <h5 className={"pt-5"}>
          My Clients
          {breadCrumbs.map((breadCrumb, index) => <span className={index == breadCrumbs.length -1 ? "activeBreadCrumb" : ''} key={breadCrumb}>
            <AiOutlineRight /> {breadCrumb}
          </span>)}
        </h5>
        <div
          className="card border-0 p-0 p-md-5 page-container"
          style={{ height: "80vh" }}
        >
          <div className="row">
            <div className="col-lg-3">
              <div style={{ position: "sticky", top: 0 }}>
                <ClientNav />
              </div>
            </div>
                <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
};
