import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from './SettingsNav.module.css'
import RemoveClientModal from "../../modals/RemoveClientModal/RemoveClientModal";

const SettingsNav = () => {
    const [modalShow, setModalShow] = useState(false);
    const {pathname} = useLocation();

    return (
        <div className="shadow-sm">
            <div className={'pt-4'}>
               
                <Link to={'/p/terms-of-services'}
                      className={`${styles.navItem} ${pathname.split('/')[2] === 'terms-of-services' && styles.active}`}>Terms of Services</Link>
                <Link to={'/p/privacy-policy'}
                      className={`${styles.navItem} ${pathname.split('/')[2] === 'privacy-policy' && styles.active}`}>Privacy Policy</Link>
                {/* <Link to={'/p/help-and-support'}
                      className={`${styles.navItem} ${pathname.split('/')[2] === 'help-and-support' && styles.active}`}>Help & Support</Link> */}
                <Link to={'/p/write-to-us'}
                      className={`${styles.navItem} ${pathname.split('/')[2] === 'write-to-us' && styles.active}`}>Write to us</Link>
            </div>
            <div className={'py-5'}/>
            <br/>
            <RemoveClientModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }}
            />
        </div>
    );
};

export default SettingsNav;