import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../config/api";

export const fetchNotfication = createAsyncThunk(
  "myClients/notification",
  async () => {
    try {
      const response = await api.get(`/messages/notification`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const chatSlicer = createSlice({
  name: "chat",
  initialState: {
    user:
      localStorage.getItem("activeUser") !== "undefined"
        ? JSON.parse(localStorage.getItem("activeUser"))
        : null,
    token: localStorage.getItem("token") ?? null,
    selectedChat: {},
    chats: [],
    messageNotification: [],
  },

  reducers: {
    setActiveUser: (state, { payload }) => {
      state.activeUser = payload;
      localStorage.setItem("activeUser", JSON.stringify(state.activeUser));
    },

    setSelectedChat: (state, { payload }) => {
      state.selectedChat = payload;
    },
    setChats: (state, { payload }) => {
      state.chats = payload;
    },
    setNotification: (state, { payload }) => {
      console.log("payload" , payload)
      state.messageNotification = payload ?? []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotfication.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.messageNotification = payload ?? []
    });
  },
});

export const { setSelectedChat, setChats, setNotification, setActiveUser } =
  chatSlicer.actions;

export default chatSlicer.reducer;
