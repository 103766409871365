import React from 'react';
import styles from './ReportCard.module.css'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineArrowRight } from "react-icons/ai";
import { changeDateFormat } from '../../config/utils';
import { useDispatch } from 'react-redux';
import { MdDelete } from 'react-icons/md'
import { api } from '../../config/api';
import { fetchReports } from '../../redux/provider/provider';
import Toast from '../Notification/toast';
const ReportCard = ({ reports , access}) => {
    const dispatch = useDispatch()
    const {clientId} = useParams()
    const deleteReport = (id) => {
        api.get(`/provider/deletReport/${id}`).then((res) => {
            if (res.data.status === "success") {
                dispatch(fetchReports(clientId))
                Toast("Successfully deleted" , "success")
            }
        })
    }
    return (
        <div className={'my-4'}>
            {
                Object.entries(reports).map(([key, value]) =>
                    <>
                        <h6 className={styles.date}>{changeDateFormat(key)}</h6>
                        <div className={'row g-4'}>
                            {value.map((val, i) => {
                                return val.report.map((v, i) => <div className={`${access ?  'col-lg-12 border-radius-th' : 'col-lg-6'} p-2 ${styles.cardRes}`} key={i}>
                                  
                                    <Link to={`/p/client/my-reports/report-detail/${clientId}/${val._id}`}
                                        className={`${access ? styles.borderradiusth : ''} shadow rounded-left ${styles.customProgramCard}`}>
                                        <h5 className={'py-1 m-0 w-100'}>{v.fileName.replace(/_/g, ' ')}</h5>
                                        <AiOutlineArrowRight className={'fs-4'} />
                                    </Link>
                                    {!access &&  <div className={`rounded-right ${styles.deleteIcon}`}>
                                        <MdDelete size={30} color='white' cursor="pointer" onClick={() => deleteReport(val._id)} />
                                    </div> }
                                    </div>
                        
                                )
                            })}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default ReportCard;