export const clientlayoutChildRoutesMapping = [
    { identifier: "client-info",  data: ['Client’s info'] },
    { identifier: "my-programs/program-detail", data: ['Client’s info', 'My Programs']},
    { identifier: "my-reports/report-detail", data: ['Client’s info', 'My Reports']},
    { identifier: "my-programs/create-program", data: ['Client’s info', 'My Programs', 'Add New Program']},
    { identifier: "my-reports/create-report", data: ['Client’s info', 'My Programs', 'Add New Report']},
    { identifier: "my-resources/create-resources", data: ['Client’s info', 'My Resources', 'Add New Resources']},
    { identifier: "my-resource/resource-detail", data: ['Client’s info', 'My Resources'] },
    { identifier: "my-resources", data: ['Client’s info', 'My Resources']},
    { identifier: "my-programs",  data: ['Client’s info', 'My Programs']},
    { identifier: "my-reports", data: ['Client’s info', 'My Reports']},
    { identifier: "provider-info", data: ['Provider’s info'] },
  ]