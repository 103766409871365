import React from "react";
import NotesCard from "../../components/NotesCard/NotesCard";
import { useParams } from "react-router-dom";
import { useGet } from "../../hooks/api/shared/generalEndpoint";
import { genericSuccessHandlerForSingleItem } from "../../config/utils";
import ConditionalSpinner from "../../components/shared/ConditionalSpinner/ConditionalSpinner";
const ProgramDetail = () => {
  const { programId } = useParams()
  const {data: fetchedProgram, loading, isError} = useGet(`/provider/program/${programId}`, genericSuccessHandlerForSingleItem('program'))

  return (
    <div className="col-lg-9">
    <div className="p-3">
      {/* <h4>Notes ({program.fileName})</h4> */}
      <ConditionalSpinner loading={loading}>
      {!isError && <NotesCard program={fetchedProgram} programId={programId} />}
      </ConditionalSpinner>
    </div>
  </div>
  );
};

export default ProgramDetail;
