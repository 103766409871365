import React from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import successfully_sent_mail from "../../assets/images/forgot_password.gif";
import styles from "../auth/SignUp/SignUp.module.css";

const VerificationModal = ({ show, onHide, invitedClient, setSearchEmail }) => {

    const handleClick = () => {
        setSearchEmail('');
        onHide();
    }

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row className={'py-5'}>
                    <Col md={12} className='mb-2 text-center'>
                        <div className={''}>
                            <img src={successfully_sent_mail} alt="" style={{ maxWidth: 100 }} />
                        </div>
                        <h5>
                            Your invitation has been sent to {invitedClient.firstName} {invitedClient.lastName}!
                        </h5>
                        <p>
                            Once {invitedClient.firstName} {invitedClient.lastName} accepts the invitation, they will appear in your client list.
                        </p>
                    </Col>
                    <Col md={3} className='mb-2' />
                    <Col md={6} className='mb-2 text-center'>
                        <button type='submit' className={styles.signupBtn} onClick={handleClick}>
                            OK
                        </button>
                    </Col>
                    <Col md={3} className='mb-2' />
                </Row>


            </Modal.Body>
        </Modal>
    );
};

export default VerificationModal;