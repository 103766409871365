import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { api } from "../../../config/api";
import { setActiveUser, setToken } from "../../../redux/auth/auth";
import Toast from "../../Notification/toast";
import styles from "./SignUp.module.css";
import VerificationModal from "./VerificationModal";
import CustSpinner from "../../Spinner/CustSpinner";
const SignUp = () => {
  const [modalShow, setModalShow] = useState(false);
  const [pin, setPin] = useState("");
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);
  const [verifiedFailed, setVerifiedFailed] = useState(false);
  const [occupation, setOccupation] = useState([]);
  const [show, setshow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [userId, setUserId] = useState("");

  const [showInputField, setShowInputField] = useState(false);
  const myArray = ["648f9265b486ed462b486f2e", "648f918ab486ed462b486eb4"];
  const [otherProvider, setOtherProvider] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const inputValue = watch("occupation");

  useEffect(() => {
    const matchCase = myArray.find((v) => v === inputValue);
    if (matchCase) {
      setShowInputField(true);
    } else {
      setShowInputField(false);
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    setOtherProvider(e.target.value);
  };

  const { activeUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const fetchOcccupatiion = () => {
    api.get("/provider/occupation").then((res) => {
      if (res.data.status == "success") {
        setOccupation(res.data.data);
      }
    });
  };
  useEffect(() => {
    fetchOcccupatiion();
  }, []);
  useEffect(() => {
    if (!show) {
      return;
    }
    // detected rendering
  }, [show]);
  const onSubmit = (data) => {
    setIsLoading(true)
    if (Object.keys(data).length) {
      if (showInputField) {
        api
          .post("provider/addOccupation", { name: otherProvider })
          .then((res) => {
            data = { ...data, occupation: res.data.data._id };
            AddUser(data);

          });
      } else {
        AddUser(data);
      }
    }
  };
  const AddUser = (data) => {
    api
      .post(`/provider/signup`, data)
      .then((response) => {
        setIsLoading(false)
        if (response.data.status === "success") {
          setUserId(response.data.data.provider._id);
          // dispatch(setActiveUser(response.data.data.provider));
          // dispatch(setToken(response.data.token));
          setModalShow(true);
        } else if (response.data.status == "failed") {
          Toast(response.data.message, "warning");
          setIsLoading(false)
        }
      })
      .catch((err) => {
        Toast("Something went wrong", "error");
        console.log("error", err);
        setIsLoading(false)
      });
  };
  const handleVerify = () => {
    setIsLoading(true)
    api
      .get(`provider/verify?_userId=${userId}&token=${pin}`)
      .then((response) => {
        setIsLoading(false)
        if (response.data.status === "success") {
          dispatch(setActiveUser(response.data.user));
          dispatch(setToken(response.data.token));
          setVerifiedSuccess(true);
          setVerifiedFailed(false);
          localStorage.setItem("loginTime", new Date().getTime());
          navigate("/p/choose-subscription");
        }
      })
      .catch((err) => {
        setVerifiedSuccess(false);
        setVerifiedFailed(true);
        setIsLoading(false)
        console.log("error", err);
      });
    // setModalShow(false)
    // navigate('/reset-password')
  };

  const showpassword = (id) => {
    setshow(!show);
    // const element = document.getElementById(id);
    // element.type = show ? "password" : "text";
  };

  return (
    <>
      <Container fluid>
        <Row>
          {/* <Col md={6} className={'px-0 d-none d-md-block'}>
                        <img className={styles.signUpImage} src={signUpImage} alt="" />
                    </Col> */}
          <Col md={12} className={styles.authcontainer}>
            <div className="text-center ">
              <img className={styles.logo} src={logo} alt="" />
            </div>
            <h1 className={styles.title}>Sign Up</h1>
            {/*<p className={styles.typography}>Fill Out The Form</p>*/}
            <Container>
              <form action="" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="First Name"
                      {...register("firstName", { required: true })}
                    />

                    <span
                      className={`${errors.firstName ? styles.visible : ""} ${
                        !errors.firstName ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                    />

                    <span
                      className={`${errors.lastName ? styles.visible : ""} ${
                        !errors.lastName ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="email"
                      placeholder="E-mail"
                      {...register("email", { required: true })}
                    />

                    <span
                      className={`${errors.email ? styles.visible : ""} ${
                        !errors.email ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Username"
                      {...register("userName", {
                        required: true,
                        pattern: /^(?=.*\d).{6,12}$/,
                      })}
                    />

                    <span
                      className={`${errors.userName ? styles.visible : ""} ${
                        !errors.userName ? styles.error : ""
                      }`}
                    >
                      Username must be between 6 and 12 characters long and
                      contain at least one number.
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className={styles.phoneno}>
                    <input
                      className={styles.input}
                      type="tel"
                      placeholder="Phone : 04xxxxxxxx"
                      {...register("phone", {
                        required: "Phone Number is required",
                        minLength: {
                          value: 10,
                          message:
                            "Phone Number must be at least 10 characters",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Phone Number cannot be longer than 10 characters",
                        },
                      })}
                    />

                    <span
                      className={`${errors.phone ? styles.visible : ""} ${
                        !errors.phone ? styles.error : ""
                      }`}
                    >
                      {errors?.phone?.message}
                    </span>
                  </Col>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="date"
                      placeholder="Date of Birth"
                      {...register("dateOfBirth", { required: true })}
                    />

                    <span
                      className={`${errors.dateOfBirth ? styles.visible : ""} ${
                        !errors.dateOfBirth ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Postcode"
                      {...register("postcode", { required: true })}
                    />

                    <span
                      className={`${errors.postcode ? styles.visible : ""} ${
                        !errors.postcode ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                  <Col md={6} className="">
                    <select
                      className={styles.input}
                      {...register("occupation", { required: true })}
                    >
                      <option value={0}> Select Occupation</option>
                      {occupation
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((el, i) => (
                          <option key={i} value={el._id}>
                            {el.name}
                          </option>
                        ))}
                    </select>
                    <span
                      className={`${errors.occupation ? styles.visible : ""} ${
                        !errors.occupation ? styles.error : ""
                      }`}
                    >
                      This field is required
                    </span>
                  </Col>
                </Row>
                {showInputField && (
                  <Row>
                    <Col md={12} className="mb-3">
                      <input
                        className={styles.input}
                        type="text"
                        placeholder="Other"
                        onChange={handleInputChange}
                      />

                      <span></span>
                      {/*<span className={`${errors.clinicName ? styles.visible : ""} ${!errors.clinicName ? styles.error : ""}`} >This field is required</span>*/}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6} className="mb-3">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Clinic Name(optional)"
                      {...register("clinicName", { required: false })}
                    />
                    <span></span>
                    {/*<span className={`${errors.clinicName ? styles.visible : ""} ${!errors.clinicName ? styles.error : ""}`} >This field is required</span>*/}
                  </Col>
                  <Col md={6} className="mb-3">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Website(optional)"
                      {...register("website", { required: false })}
                    />
                    <span></span>
                    {/*<span className={`${errors.clinicName ? styles.visible : ""} ${!errors.clinicName ? styles.error : ""}`} >This field is required</span>*/}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Address(optional)"
                      {...register("address", { required: false })}
                    />
                    <span></span>
                    {/*<span className={`${errors.clinicName ? styles.visible : ""} ${!errors.clinicName ? styles.error : ""}`} >This field is required</span>*/}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="position-relative">
                    <input
                      className={`${styles.input} ${styles.passwordInputPadding}`}
                      type={show ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      name="password"
                      {...register("password", {
                        required: true,
                        minLength: 8,
                        pattern:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                      })}
                    />

                    {show ? (
                      <i
                        onClick={() => showpassword("password")}
                        className={styles.pass}
                      >
                        <FaEye />
                      </i>
                    ) : (
                      <i
                        onClick={() => showpassword("password")}
                        className={styles.pass}
                      >
                        <FaEyeSlash />
                      </i>
                    )}
                    </div>
                    <span
                      className={`${errors.password ? styles.visible : ""} ${
                        !errors.password ? styles.error : ""
                      }`}
                    >
                      Password must be at least 8 characters long and contain at
                      least one lowercase letter, one uppercase letter, and one
                      number and one symbol
                    </span>
                  </Col>
                  <Col md={6}>
                    <div className="position-relative">
                    <input
                      className={`${styles.input} ${styles.passwordInputPadding}`}
                      type={confirmShow ? "text" : "password"}
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      {...register("confirmPassword", {
                        validate: (value) => value === watch("password"),
                      })}
                    />
                    {confirmShow ? (
                      <i
                        onClick={() => setConfirmShow(!confirmShow)}
                        className={styles.pass}
                      >
                        <FaEye />
                      </i>
                    ) : (
                      <i
                      onClick={() => setConfirmShow(!confirmShow)}
                        className={styles.pass}
                      >
                        <FaEyeSlash />
                      </i>
                    )}
                    </div>
                    <span
                      className={`${
                        errors.confirmPassword ? styles.visible : ""
                      } ${!errors.confirmPassword ? styles.error : ""}`}
                    >
                      Password didn't match
                    </span>
                  </Col>
                </Row>

                <div className="d-flex">
                  <div className="w-75">
                    <div className="d-flex">
                      <div className="me-2">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          {...register("checkbox", { required: true })}
                        />
                      </div>
                      <p className="termstext">
                        I agree with the{" "}
                        <span className={styles.link}>Terms of Services</span>{" "}
                        and the{" "}
                        <span className={styles.link}>Privacy Policy</span> of
                        Link Me Digital Health.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span
                    className={`${errors.checkbox ? styles.visible : ""} ${
                      !errors.checkbox ? styles.error : ""
                    }`}
                  >
                    You must agree with the Terms of Services and the Privacy
                    Policy of Link Me Digital Health.
                  </span>
                </div>
                <Row>
                  <Col md={3} className="" />
                  <Col md={6} className=" mt-1">
                    <button type="submit" className={styles.signupBtn} disabled={isLoading}>
                      {isLoading ? <CustSpinner /> : "Sign Up"}
                    </button>
                    <p className={"text-center"} onClick={() => navigate("/")}>
                      Already registered?{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#77ACA2",
                          textDecoration: "underline",
                        }}
                      >
                        Login
                      </span>
                    </p>
                  </Col>
                  <Col md={3} className="" />
                </Row>
              </form>
            </Container>
          </Col>
        </Row>
      </Container>
      <VerificationModal
        show={modalShow}
        setPin={setPin}
        setVerifiedSuccess={setVerifiedSuccess}
        verifiedSuccess={verifiedSuccess}
        verifiedFailed={verifiedFailed}
        setVerifiedFailed={setVerifiedFailed}
        pin={pin}
        onHide={handleVerify}
        setModalShow={(v) => setModalShow(v)}
        loading= {isLoading}
      />
    </>
  );
};

export default SignUp;
