import React from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import styles from "../auth/forgotPassword/ForgotPassword.module.css";
import verification_success from "../../assets/images/success_password_change.gif";

const SuccessModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row className={'py-5'}>
                    <Col md={3} className='mb-2' />
                    <Col md={6} className='mb-2 text-center'>
                        <div className={''}>
                            <img src={verification_success} alt="" style={{ maxWidth: 150 }} />
                        </div>
                        <p>
                            Your payment is successful.
                        </p>
                        <button type='submit' className={styles.LoginBtn} onClick={props.onHide}>
                            Done
                        </button>
                    </Col>
                    <Col md={3} className='mb-2' />
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;