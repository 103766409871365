import React, { useState } from "react";
import styles from "./WriteToUs.module.css";
import { AiOutlineRight } from "react-icons/ai";
import SettingsNav from "../../components/shared/SettingsNav/SettingsNav";

const WriteToUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [slectOption, setSelectOption] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Message:", message);
    console.log("slectoptions", slectOption);
  };
  return (
    <div className={"bg-light"} style={{ height: "100vh" }}>
      <div className={"container pt-5"}>
        <h5 className={"pt-5"}>
          {" "}
          Settings
          <span className={"activeBreadCrumb"}>
            <AiOutlineRight /> Write to us
          </span>
        </h5>
        <div
          className="card border-0 p-0 p-md-5 page-container"
          style={{ height: "80vh" }}
        >
          <div className="row">
            <div className="col-lg-3">
              <div style={{ position: "sticky", top: 0 }}>
                <SettingsNav />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="p-3">
                <h4>Write to us</h4>
                <br />
                <form action="" onSubmit={handleSubmit}>
                  <select
                    name=""
                    id=""
                    className={`form-select ${styles.input}`}
                    onChange={(e) => setSelectOption(e.target.value)}
                  >
                    <option
                      value="General Query"
                      onChange={(e) => setSelectOption(e.target.value)}
                    >
                      General Query
                    </option>
                    <option
                      value="Emergency Query"
                      onChange={(e) => setSelectOption(e.target.value)}
                    >
                      Emergency Query
                    </option>
                  </select>
                  <br />
                  <input
                    type="text"
                    className={`form-control ${styles.input}`}
                    placeholder={"Full Name"}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <br />
                  <input
                    type="email"
                    className={`form-control ${styles.input}`}
                    placeholder={"Email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />

                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className={`form-control ${styles.input}`}
                    placeholder={"Write a message..."}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <a
                    href={`mailto:${email}?subject=${slectOption}&body=${message}`}
                    className={`mt-3 ${styles.btn}`}
                    type="submit"
                  >
                    Send Email
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteToUs;
