
import * as Swal from 'sweetalert2'
const Toast =   (msg, icon) => Swal.fire({
    position: 'top-end',
    icon: `${icon}`,
    title: `${msg}`,
    showConfirmButton: false,
    toast: true,
    timer: 5000
})
export default Toast