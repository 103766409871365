import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "react-bootstrap";
const CustSpinner = () => {
  return (
    <>
      <Spinner
        animation="border"
        role="status"
        className="spinner-border text-white"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </>
  );
};

export default CustSpinner;
