import React from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import ConfirmDelete from "../../../assets/images/confirmRemove.gif";
import styles from "./LogOutModal.module.css";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { removeToken , removeActiveUser} from '../../../redux/auth/auth';

const LogOutModal = (props) => {
    const dispatch = useDispatch()
    const propData = {...props}
    const navigate = useNavigate()
    const submitClicked = () =>{
        propData.onHide()
        localStorage.removeItem('token')
        localStorage.removeItem('activeUser')
        dispatch(removeToken())
        dispatch(removeActiveUser())
        navigate('/login')
        window.location.reload()
    }
    return (
        <Modal
            show={propData.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                    <Row className={'py-5'}>
                        <Col md={12} className='mb-2 text-center'>
                            <div className={''}>
                                <img src={ConfirmDelete} alt="" style={{maxWidth: 100}}/>
                            </div>
                            <p className={'fw-bold'}>
                                Are you sure you want to sign out?
                            </p>
                        </Col>
                        <Col md={3} className='mb-2'/>
                        <Col md={6} className='mb-2 text-center'>
                            <button type='submit' className={styles.doNotRemoveBtn} onClick={propData.onHide}>
                                No, I’ve changed my mind
                            </button>
                            <button type='submit' className={styles.SignOutBtn} onClick={submitClicked}>
                                Yes, sign out
                            </button>
                        </Col>
                        <Col md={3} className='mb-2'/>
                    </Row>
            </Modal.Body>
        </Modal>
    );
};

export default LogOutModal;