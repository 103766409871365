import React from 'react';
import styles from './MyReports.module.css'
import ReportCard from "../../components/ReportCard/ReportCard";
import { fetchReports } from '../../redux/provider/provider';
// import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
const MyReports = () => {
    const { myReports } = useSelector(state => state.connectedProviders)
    const dispatch = useDispatch()
    const { clientId } = useParams();
    useEffect(() => {
        if(clientId) dispatch(fetchReports(clientId))
    }, [clientId])
    return (
        <div className="col-lg-9">
        <Link className={`position-absolute ${styles.addButton}`} to={`/p/client/my-reports/create-report/${clientId}`}><BsPlusLg/></Link>
        <div className="p-3">
            <h4>Reports</h4>
            {/* {reports.data && reports.data.map((v)=>)} */}
            {myReports.data  && <ReportCard reports={myReports.data} clientId={clientId}/>}
           
        </div>
    </div>
    );
};

export default MyReports;