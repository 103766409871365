import React, { memo, useEffect, useMemo, useState } from 'react';
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillBellFill } from "react-icons/bs";
import { FaCog, FaCreditCard, FaUserAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { io } from "socket.io-client";
import logo from '../../assets/images/logoWhite.png';
import { api } from '../../config/api';
import { socket } from '../../config/config';
import { fetchNotfication, setNotification } from '../../redux/chat/chat';
import { fetchMyClients } from '../../redux/myClients/myClients';
import { getAllConnectedProviders } from '../../redux/provider/provider';
import Toast from '../Notification/toast';
import NotificationCard from "../NotificationCard/NotificationCard";
import ClientInvitationSentModal from "../modals/ClientInvitationSentModal";
import LogOutModal from "../modals/LogOutModal/LogOutModal";
import styles from "./NavBar.module.css";
import { removeToken, removeActiveUser } from '../../redux/auth/auth';
const NavBar = memo(() => {
    const [modalShow, setModalShow] = useState(false);
    const [logoutModalShow, setLogoutModalShow] = useState(false);
    const { pathname } = useLocation();
    const [navMenu, setNavMenu] = useState(false);
    const [searchEmail, setSearchEmail] = useState('');
    const [invitedClient, setInvitedClient] = useState('');
    const { activeUser } = useSelector(state => state.auth);
    const [notification, setsNotification] = useState([])
    const [appNotfication, setappNotfication] = useState([])
    const { messageNotification } = useSelector(state => state.chat)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleSearchEmail = (e) => {
        setSearchEmail(e.target.value);
    }

    useEffect(() => {
        socket.emit("setup", activeUser);
        let timer;
        let i = 0;
        const socket1 = io(process.env.REACT_APP_DOMAIN_URL, { "path": "/api/v1/notifications/", "forceNew": true, "reconnectionAttempts": 3, "timeout": 2000 });
        socket1.on('newNotification', (notificat) => {
            const foundNotfication = appNotfication.find((not) => not._id == notificat._id)

            if (!foundNotfication && !!(notificat.reciever == activeUser._id)) {

                setappNotfication([...appNotfication, notificat]);
                // fetchappNotification()
                if (notificat.type == "invitaition" && i <= 1) {

                    fetchsNotification()
                    api.get(`/provider/deleteNotification/${notificat._id}`).then((res) => {
                        if (res.data.status = "success") {
                            fetchappNotification()
                        }
                    })

                } else if (notificat.type == "linked" && i <= 1) {

                    dispatch(getAllConnectedProviders(activeUser._id))
                } else if (notificat.type === "accept" && i <= 1) {
                    dispatch(fetchMyClients());
                }
                i = +1;
            }

        });
        return () => {
            socket1.disconnect();
            // clear the timer when unmounting
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, [activeUser._id]);

    useEffect(() => {
        socket.on("message received", (newMessageReceived) => {
            messageNotification && dispatch(setNotification([newMessageReceived, ...messageNotification]))
        });
    })
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const handleInvite = () => {
        if (validateEmail(searchEmail)) {
            api.get(`/provider/searchClient/${searchEmail}`)
                .then(response => {

                    if (response.data.status === 'success' && response.data.data != null) {
                        const clientId = response.data.data._id;
                        setInvitedClient(response.data.data);
                        api.post('/provider/inviteClient/', { clientId })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    setModalShow(true);
                                }
                            }).catch(err => {
                                console.log(err)
                            });
                    } else {
                        Toast("No client is registered with this email", 'warning')
                    }

                }).catch(err => {
                    console.log(err)
                });
        } else (
            Toast("Please enter a valid email", 'warning')
        )
    }
    useEffect(() => {
        setNavMenu(pathname === `/p/payment` || pathname === `/p/choose-subscription`)
    }, [pathname])
    const fetchsNotification = useMemo(() => () => {
        api.get('/provider/clientRequests').then((res) => setsNotification(res.data.data))
    }, [])
    function checkSession() {
        const loginTime = localStorage.getItem('loginTime');
        if (loginTime) {
            const diffInMs = new Date().getTime() - loginTime;
            const diffInHours = diffInMs / (1000 * 60 * 60);
            if (diffInHours > 12) {
                // logout user
                localStorage.clear();
                dispatch(removeToken())
                dispatch(removeActiveUser())
                window.location.href = '/login'; // redirect to login page
            }
        }
    }
    useEffect(() => {
        checkSession()
        const intervalId = setInterval(checkSession, 60000); // check every minute
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        fetchsNotification()
        fetchappNotification()
        dispatch(fetchNotfication())
    }, [])
    const acceptRequest = (id) => {
        setIsLoading(true)
        api.post(`/provider/acceptInvite/${id}`).then((res) => {
            setIsLoading(false)
            toast('Accepted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            fetchsNotification()
            dispatch(fetchMyClients());


        }).catch((err) => {
            setIsLoading(false)
            console.log(EvalError)
        })
    }
    const fetchappNotification = () => {
        api.get(`/provider/appNotfication/${activeUser._id}`).then((res) => {
            if (res.data.status = "success") {
                setappNotfication(res.data.data ?? [])
            }
        })
    }
    const updtaeNotification = (notif) => {
        if (notif.type == "linked") {
            navigate(`/p/client/linked-providers/${notif.sharedClientId}/provider-info/${notif.sender._id}`)
        }
        // api.get(`/provider/updateNotfication/${notif._id}`).then((res) => {
        //     if (res.data.status = "success") {

        //         // setappNotfication(res.data.data ?)
        //         // navigate('/p/my-programs/program-detail', { state: { program: notif.programId.program, id: notif.programId._id } })

        //     }
        // })
    }
    const updtaeAllNotification = () => {
        api.get(`/provider/updateAllNotfication/${activeUser._id}`).then((res) => {
            if (res.data.status = "success") {
                fetchappNotification()
                // setappNotfication(res.data.data ?)
                // navigate('/p/my-programs/program-detail', { state: { program: notif.programId.program, id: notif.programId._id } })

            }
        })
    }

    const deleteMessageNotfication = () => {
        api.delete(`/messages/${activeUser._id}`).then((res) => {
            dispatch(setNotification([]))
            dispatch(fetchNotfication())

        })
    }

    return (
        <>
            <Navbar expand="lg" fixed={'top'}
                // className={'bg-white'}
                className={styles.navBar}
            >
                <Container>
                    <div onClick={() => navigate('/')}><img src={logo} alt="" style={{ width: 100 }} /></div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {
                            !navMenu ?
                                <Nav className="ms-auto">
                                    <div className="d-flex me-xl-5 me-lg-0">
                                        <div className="input-group flex-nowrap me-xl-2 me-lg-0">
                                            <span className={`input-group-text ${styles.adornment}`}
                                                id="addon-wrapping"><AiOutlineSearch /></span>
                                            <input onChange={handleSearchEmail} type="email" className={`form-control ${styles.searchInput}`}
                                                placeholder="Search client by email" aria-label="search"
                                                aria-describedby="addon-wrapping" name="client" value={searchEmail} />
                                        </div>
                                        <button className="btn btn-dark px-4" onClick={handleInvite}>Invite</button>
                                    </div>
                                    <Link to={'/p/my-clients'} className={'nav-link text-white fw-bold my-auto mx-xl-3 mx-lg-0'}>My Clients</Link>
                                    {/*<Link to={'/p/my-profile'} className={'nav-link text-white fw-bold my-auto mx-xl-3 mx-lg-0'}>My Profile</Link>
                            <Link to={'/p/settings'} className={'nav-link text-white fw-bold my-auto mx-xl-3 mx-lg-0'}>Settings</Link>*/}
                                    <Link to={'/p/message'}
                                        className={'nav-link text-white fw-bold my-auto fs-4 pt-0 mx-xl-3 mx-lg-0'} style={{ position: 'relative' }}><MdMail onClick={deleteMessageNotfication} /> {messageNotification?.length > 0 && <div style={{
                                            position: 'absolute',
                                            top: "3px",
                                            right: "4px",
                                            backgroundColor: 'red',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                        }}></div>}</Link>
                                    <NavDropdown onClick={updtaeAllNotification} title={<><BsFillBellFill className={'fw-bold fs-4 pt-0 text-white'} style={{ position: 'relative' }} /> {(appNotfication.find((v) => v.read == false) || notification.length > 0) && <div style={{
                                        position: 'absolute',
                                        top: "3px",
                                        right: "4px",
                                        backgroundColor: 'red',
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                    }}></div>} </>}
                                        id="basic-nav-dropdown" className="notification-bell my-auto">
                                        <>
                                            {notification.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                ?.map((v, i) => <NotificationCard key={i} notif={v} isLoading={isLoading} btn={true} accept={(res) => acceptRequest(res)} />)}
                                            {appNotfication.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                ?.map((v, i) => <NotificationCard key={i} notif={v} btn={false} updtaeNotification={(res) => updtaeNotification(res)} />)}

                                            <NavDropdown.Divider className={'m-0'} />
                                        </>
                                    </NavDropdown>


                                    <p className={'nav-link text-white fw-bold my-auto fs-4 pt-0'}>
                                        <img src={activeUser.imageUri} alt="" style={{ height: 30, width: 30, objectFit: "cover", borderRadius: "50%" }} />
                                        {/*<img src={providerImage} alt="" className={styles.profileImage} />*/}
                                    </p>
                                    <NavDropdown title={<span className={'text-white'}>{activeUser?.firstName}</span>} id="basic-nav-dropdown" className={'fw-bold'}>
                                        <Link to={'/p/my-profile'} className={`dropdown-item m-0 p-3 ${styles.otherMenu}`}
                                        ><FaUserAlt className={'fs-5 me-2'} />My Profile</Link>
                                        
                                        <Link to={'p/terms-of-services'} className={`dropdown-item m-0 p-3 ${styles.otherMenu}`}
                                        ><FaCog className={'fs-5 me-2'} />Terms of Services</Link>
                                        <Link to={'/p/choose-subscription'} className={`dropdown-item m- p-3 ${styles.otherMenu}`}
                                        ><FaCreditCard className={'fs-4 me-2'} />My Subscription</Link>
                                        <p className={`dropdown-item m-0 p-3 ${styles.signOut}`}
                                            onClick={() => setLogoutModalShow(true)}
                                        ><TbLogout className={'fs-4'} />Sign Out</p>
                                    </NavDropdown>
                                </Nav>

                                :
                                <Nav className="ms-auto">
                                <NavDropdown title={<span className={'text-white'}>{activeUser?.firstName}</span>} id="basic-nav-dropdown" className={'fw-bold'}>
                                    <p className={`dropdown-item m-0 p-3 ${styles.signOut}`}
                                        onClick={() => setLogoutModalShow(true)}
                                    ><TbLogout className={'fs-4'} />Sign Out</p>
                                </NavDropdown>
                                </Nav>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ClientInvitationSentModal
                invitedClient={invitedClient}
                setSearchEmail={setSearchEmail}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }}
            />
            <LogOutModal
                show={logoutModalShow}
                onHide={() => {
                    setLogoutModalShow(false)
                }}
            />
        </>
    );
})

export default NavBar;