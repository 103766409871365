import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from "../../config/api";

// First, create an async thunk for getting all linked providers
export const getAllConnectedProviders = createAsyncThunk(
  'providers/getAllLinked',
  async (senderProviderId) => {
    const response = await api.get(`/provider/getLinkedProviders?senderProviderId=${senderProviderId}`)
    console.log(response.status , response.statusText)
    return response.data;

  }
);

// Then, create another async thunk for linking a provider
export const addlinkProvider = createAsyncThunk(
  'providers/link',
  async (providerInfo) => {
  
    const response = await  api.post(`/provider/sendRequesttoProviders` , providerInfo);
    return response.data;
  }
);

export const providerAsyncThunk =async (provider) => async (dispatch) => {

  try {

  await dispatch(addlinkProvider(provider));
  } catch (error) {
    console.error(error);
  }
};

export const fetchProgram = createAsyncThunk(
  'providers/program',
  async (_id) => {
    const response = await api.get(`/provider/programForClient/${_id}`)
    return response.data.data;
  }
);
export const fetchReports = createAsyncThunk(
  'providers/report',
  async (_id) => {
    const response = await api.get(`/provider/reportForClient/${_id}`)
    return response.data;
  }
);
export const fetchResource = createAsyncThunk(
  'providers/resource',
  async (_id) => {
    const response = await api.get(`/provider/resourceForClient/${_id}`)
    return response.data;
  }
);
// Next, create the slice using the createSlice function
const providersSlice = createSlice({
  name: 'providers',
  initialState: {
    conectedProviders: [],
    myResources : [],
    myReports : [] ,
    myPrograms  : [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllConnectedProviders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllConnectedProviders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.conectedProviders = action.payload;
      })
      .addCase(getAllConnectedProviders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addlinkProvider.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addlinkProvider.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update the list of linked providers by adding the newly linked provider
        state.conectedProviders.push(action.payload);
      })
      .addCase(addlinkProvider.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchResource.fulfilled , (state , action) => {
        state.myResources =  action.payload
      })
      .addCase(fetchReports.fulfilled , (state , action) => {
        state.myReports =  action.payload
      })
      .addCase(fetchProgram.fulfilled , (state , action) => {
        state.myPrograms =  action.payload
      })
  }
});

// Finally, export the slice's reducer and actions
export const { } = providersSlice.actions;
export default providersSlice.reducer;
