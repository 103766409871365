import React, { useState } from 'react';
import styles from './PaymentCard.module.css'
import amex from '../../assets/images/paymentType/american-express.png'
import mastercard from '../../assets/images/paymentType/mastercard.png'
import visa from '../../assets/images/paymentType/visa.png'
import { FaCalendarDay, FaCreditCard, FaLock } from "react-icons/fa";
import SuccessPaidModal from "../SuccessPaidModal/SuccessPaidModal";
import { useLocation } from 'react-router-dom';
import './style.css'
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';


const PaymentCard = ({ cardType }) => {
    const { state } = useLocation()
    const [modalShow, setModalShow] = useState(false);
    const { clientSecret, subscriptionId, price } = state;
    const [name, setName] = useState('');
    const [messages, _setMessages] = useState('');

    const [paymentIntent, setPaymentIntent] = useState();
    const navigate = useNavigate()
    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    }


    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return '';
    }

    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);

        // Use card Element to tokenize payment details
        let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,

                billing_details: {
                    name: name,
                }
            }
        });

        if (error) {
            // show error and collect new card details.
            setMessage(error.message);
            return;
        }
        setPaymentIntent(paymentIntent);
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
        // setModalShow(true)
        navigate('/')
    }
    return (
        <>
            <h5>Payment Method</h5>
            <form onSubmit={handleSubmit}>
                <div
                    className={`card h-100 w-100 p-3 position-relative ${cardType === 'method' ? styles.method : styles.summery}`}>

                    {
                        cardType === 'method' &&
                        <>
                            {/* <label htmlFor="paymentType"><input type="radio" name="paymentType" />&nbsp;Paypal</label>
                            <div>
                                <hr style={{ color: "#ADADAD" }} />
                            </div> */}
                            <div className={'d-flex justify-content-between'}>
                                {/* <label htmlFor="paymentType"><input type="radio" name="paymentType" />&nbsp;Credit card</label> */}
                                <div>
                                    <img src={mastercard} className={'img-fluid'} alt="" />&nbsp;
                                    <img src={amex} className={'img-fluid'} alt="" />&nbsp;
                                    <img src={visa} className={'img-fluid'} alt="" />
                                </div>
                            </div>

                            <div className={'w-75 py-3'}>

                                <div className="Card__Upper">
                                    <div>
                                        <div className="Card__UpperText">Please enter your billing information below:</div>
                                    </div>
                                    <div className="Card__Icon"><i className="fas fa-lock"></i></div>
                                </div>

                                <input type="text" className="Card__Input" placeholder="Name on card" />

                                <div className="Card__Stripe">
                                    <div>
                                        <div className="Card__StripeText">Card number</div>
                                        <CardNumberElement className="Card__StripeInput" />
                                    </div>
                                    <div>
                                        <div className="Card__StripeText">Expiration date</div>
                                        <CardExpiryElement className="Card__StripeInput Card__StripeInput--Expiry" options={{ style: { base: { fontSize: '16px' } } }} />
                                    </div>
                                    <div>
                                        <div className="Card__StripeText">CVC</div>
                                        <CardCvcElement className="Card__StripeInput Card__StripeInput--CVC" options={{ style: { base: { fontSize: '16px' } } }} />
                                    </div>
                                </div>

                                <div>{messages}</div>


                                {/* <label htmlFor="" className={'py-2 fs-6'}>Card number</label>
                            <div className="input-group flex-nowrap">
                                <span className={`input-group-text ${styles.adornment}`} id="addon-wrapping"><FaCreditCard /></span>
                                <input type="text" className={`form-control ${styles.paymentInput}`} placeholder="xxxx-xxxx-xxxx-xxxx" aria-label="Username"
                                    aria-describedby="addon-wrapping" />
                            </div>
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <label htmlFor="" className={'py-2 fs-6'}>Expiry date</label>
                                    <div className="input-group flex-nowrap">
                                        <span className={`input-group-text ${styles.adornment}`} id="addon-wrapping"><FaCalendarDay /></span>
                                        <input type="text" className={`form-control ${styles.paymentInput}`} placeholder="MM/YY" aria-label="Username"
                                            aria-describedby="addon-wrapping" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="" className={'py-2 fs-6'}>CVV</label>
                                    <div className="input-group flex-nowrap">
                                        <span className={`input-group-text ${styles.adornment}`} id="addon-wrapping"><FaLock /></span>
                                        <input type="text" className={`form-control ${styles.paymentInput}`} placeholder="" aria-label="Username"
                                            aria-describedby="addon-wrapping" />
                                    </div>
                                </div>
                            </div> */}
                            </div>
                            <div className={'py-2 py-lg-5'} />
                            <div className={`w-100 text-center px-3 ${styles.buttonContainer}`}>
                                <button className={'btn btn-lg w-50 my-3'} type="submit">Pay now</button>
                            </div>
                        </>
                    }
                    {
                        cardType === 'summery' &&
                        <div className={'mt-4'}>
                            <div className={'d-flex justify-content-between'}>
                                <p className={'fw-semibold'}>Monthly bill</p>
                                {price?.unit_amount / 100}
                            </div>
                            <div>
                                <hr style={{ color: "#ADADAD" }} />
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                <p className={'fw-semibold'}>VAT</p>
                                {/* <p className={'fw-bold'}>+$1.99</p> */}
                            </div>
                            <div>
                                <hr style={{ color: "#ADADAD" }} />
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                <p className={'fw-bold'}>Total pay (AUS dollars)</p>
                                <p className={'fw-bold'}>+ {price?.unit_amount / 100}</p>
                            </div>
                        </div>
                    }

                    {
                        cardType === 'summery' &&
                        <>
                            <p><FaLock /> Your transaction is secured with SSL & Stripe encryption</p>
                        </>
                    }
                </div>
            </form>
            <SuccessPaidModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }}
            />
        </>
    );
};

export default PaymentCard;