import React from 'react';
import ProviderData from "../../components/ProviderData/ProviderData";
import ProgramCard from "../../components/ProgramCard/ProgramCard";
import ReportCard from "../../components/ReportCard/ReportCard";
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { api } from '../../config/api';
import ConditionalSpinner from '../../components/shared/ConditionalSpinner/ConditionalSpinner';
import { toast } from 'react-toastify';

const ProviderInfo = () => {
    // const { state } = useLocation()
    // const { provider } = state;
    const { clientId, providerId } = useParams();
    const [programs, setPrograms] = useState({})
    const [reports, setReports] = useState({})
    const [fetchedProvider, setFetchedProvider] = useState({})
    const [loading, setLoading] = useState(true)

    const fetchProram = () => {
        return api.post('/provider/programForConnect', {
            clientId,
            providerId
        }).then((res) => {
            if(res.data.status == "success") {
                setPrograms(res.data.data)
             }
        })
    }

    const fetchReports = () => {
        return api.post('/provider/reportForConnect', {
            clientId,
            providerId
        }).then((res) =>{
         if(res.data.status == "success") {
            setReports(res.data.data)
         }
        })

    }
    const fetchProvider = () => {
        return api.get(`/provider/getProvider/${clientId}/${providerId}`).then((res) =>{
         if(res.data.status == "success") {
            console.log(res);
            setFetchedProvider(res.data.data)
         }
        })

    }

    useEffect(() => {
        fetchProvider().then(() => {
            Promise.all([
                fetchProram(),
                fetchReports()
            ]).then(() => {
                setLoading(false)
            }).catch(() => {
                toast.error("Something went wrong, try again")
            })
        }).catch(() => {
            toast.error("Something went wrong, try again")
            })
    },[])
    return (
        <ConditionalSpinner loading={loading}>
            <div className="col-lg-9">
                <div className="p-3">
                    {<ProviderData provider={fetchedProvider} />}
                    <div className="row">
                        <div className="col-lg-6">
                            <h4>Programs</h4>
                            <ProgramCard program={programs}  access={true}/>
                        </div>
                        <div className="col-lg-6">
                            <h4>Reports</h4>
                            <ReportCard reports={reports} access={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </ConditionalSpinner>
    );
};

export default ProviderInfo;