import React, { useState } from 'react';
import Logo from '../../../assets/images/logo.png'
import auth_bg from '../../../assets/images/auth_bg.png'
import successfully_password_change from '../../../assets/images/success_password_change.gif'
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import styles from './ForgotPassword.module.css';
import { useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from "react-router-dom";
import { api } from '../../../config/api';
import { toast } from 'react-toastify';
const ResetPassword = () => {
    const [changeSuccess, setChangeSuccess] = useState(false)
    const [show, setshow] = useState(false)
    const location = useLocation();
    const email = location?.state?.email || "";
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        api.post('/provider/resetPassword', { email, password: data.password, token: data.token }).then(res => {
            if(res.data.status === "success") {
                setChangeSuccess(true)
            }else if (res.data.status === "fail") {
                    toast.error(res.data.message)
            }
        })
    };
    const showpassword = (id) => {
        setshow(!show)
        const element = document.getElementById(id)
        element.type = show ? 'password' : 'text';
    }
    const navigate = useNavigate()
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12} className={`my-auto ${styles.authcontainer}`}>
                        <div>
                            <div className='text-center '>
                                <img className={styles.logo} src={Logo} alt="" />
                            </div>
                            <div className={'row'}>
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <h1 className={`${styles.title} mt-5`}>Create new password</h1>
                                    <p className={`${styles.typography} px-5`}>Password must contain minimum 8 characters, at least 1<br /> upper case, 1 lower case, 1 number and 1 symbol.</p>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <Container>
                                {
                                    !changeSuccess ?
                                        <form action="" onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={3} className='mb-2' />
                                                <Col md={6} className='position-relative'>
                                                    <input className={styles.input} type="text" id='token'
                                                        placeholder='Enter code that you have received on you mail' {...register("token", { required: true })} />

                                                    <span
                                                        className={`${errors.token ? styles.visible : ""} ${!errors.token ? styles.error : ""}`}>Password didn't match</span>
                                                </Col>
                                                <Col md={3} className='mb-2' />
                                            </Row>
                                            <Row>
                                                <Col md={3} className='mb-2' />
                                                <Col md={6} className='position-relative'>
                                                    <input className={styles.input} type="password" id='password'
                                                        placeholder='Password'
                                                        name='password'
                                                        {...register("password", {
                                                            required: true,
                                                            minLength: 8,
                                                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                                        })}
                                                    />
                                                    {show ? <i onClick={() => showpassword('password')} className={styles.pass}><FaEye /></i> : <i onClick={() => showpassword('password')} className={styles.pass}><FaEyeSlash /></i>}
                                                    <span
                                                        className={`${errors.password ? styles.visible : ""} ${!errors.password ? styles.error : ""}`}>Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, and one number and one symbol</span>
                                                </Col>

                                                <Col md={3} className='mb-2' />
                                            </Row>
                                            <Row>
                                                <Col md={3} className='mb-2' />
                                                <Col md={6} className='position-relative'>
                                                    <input className={styles.input} type="password" id='confirmPassword'
                                                        placeholder='Confirm Password' {...register("confirmPassword", { validate: value => value === (watch('password')) })} />
                                                    {show ? <i onClick={() => showpassword('confirmPassword')} className={styles.pass}><FaEye /></i> : <i onClick={() => showpassword('confirmPassword')} className={styles.pass}><FaEyeSlash /></i>}
                                                    <span
                                                        className={`${errors.confirmPassword ? styles.visible : ""} ${!errors.confirmPassword ? styles.error : ""}`}>Password didn't match</span>
                                                </Col>
                                                <Col md={3} className='mb-2' />
                                            </Row>
                                            <Row>
                                                <Col md={3} className='mb-2' />
                                                <Col md={6} className='mb-2 mt-3'>
                                                    <button type='submit' className={styles.LoginBtn}>
                                                        Reset Password
                                                    </button>
                                                </Col>
                                                <Col md={3} className='mb-2' />
                                            </Row>


                                        </form>
                                        :
                                        <>
                                            <div className={'text-center'}>
                                                <img src={successfully_password_change} alt="" style={{ maxWidth: 150 }} />
                                            </div>
                                            <Row>
                                                <Col md={3} className='mb-2' />
                                                <Col md={6} className='mb-2 mt-3'>
                                                    <button type='submit' className={styles.LoginBtn} onClick={() => navigate('/')}>
                                                        Login
                                                    </button>
                                                </Col>
                                                <Col md={3} className='mb-2' />
                                            </Row>
                                        </>
                                }
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ResetPassword;