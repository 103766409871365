import React, { useEffect, useState } from "react";
import providerImage from "../../assets/images/default.webp";
import styles from "./MyProfile.module.css";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import S3 from "react-aws-s3";
import { s3Config } from "../../config/config";
import { api } from "../../config/api";
import { setActiveUser } from "../../redux/auth/auth";
window.Buffer = window.Buffer || require("buffer").Buffer;

const MyProfile = () => {
  const { activeUser } = useSelector((state) => state.auth);
  const {
    firstName,
    lastName,
    email,
    phone,
    dateOfBirth,
    postCode,
    clinicName,
    occupation,
    imageUri,
    website,
    address,
  } = activeUser;
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);

  const [userData, setUserData] = useState({ occupation: occupation._id });
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(providerImage);
  const [selectoccupation, setOccupation] = useState([]);
  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const fileInput = React.useRef();

  const handleUpdate = (event) => {
    event.preventDefault();
    let newArr = fileInput.current.files;
    if (newArr.length === 0) {
      updateProfile(userData);
    }
    for (let i = 0; i < newArr.length; i++) {
      handleUpload(newArr[i]);
    }
  };
  const handleUpload = (file) => {
    let newFileName = file.name.replace(/[\s.]+/g, "");
    const ReactS3Client = new S3(s3Config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
        console.log("success", data);
        userData.imageUri = data.location;
        updateProfile(userData);
      } else {
        console.log("fail", data);
      }
    });
  };

  const updateProfile = (data) => {
    api.put("/provider/updateProfile", data).then((res) => {
      console.log(res);
      dispatch(setActiveUser(res.data.data));
      setIsDisabled(true);
    });
  };

  const fetchOcccupatiion = () => {
    api.get("/provider/occupation").then((res) => {
      if (res.data.status === "success") {
        setOccupation(res.data.data);
      }
    });
  };
  useEffect(() => {
    fetchOcccupatiion();
  }, []);
  return (
    <div className={"bg-light"} style={{ height: "100vh" }}>
      <div className={"container pt-5"}>
        <h5 className={"pt-5"}>My Profile</h5>
        <div
          className="card border-0 p-5 page-container"
          style={{ height: "80vh" }}
        >
          <div className="row">
            <div className="col-lg-3">
              <div
                style={{ width: "fit-content" }}
                className={"mx-auto text-center"}
              >
                <div className={"position-relative"}>
                  {selectedFile && (
                    <img src={preview} alt="" className={styles.profileImage} />
                  )}
                  {!selectedFile && (
                    <img
                      src={imageUri}
                      alt=""
                      className={styles.profileImage}
                    />
                  )}
                  {!isDisabled && (
                    <>
                      <input
                        type="file"
                        ref={fileInput}
                        onChange={onSelectFile}
                        className={styles.hiddenInput}
                      />
                      <span className={styles.photoEdit}>
                        <MdOutlineAddAPhoto />
                      </span>
                    </>
                  )}
                </div>
                <h4 className={"mt-3"}>
                  {firstName} {lastName}
                </h4>
                <p className={"fw-bold"}>{occupation.name}</p>
              </div>
            </div>
            <div className="col-lg-9">
              <div className={"d-flex justify-content-between"}>
                <h3>My Profile</h3>
                {isDisabled ? (
                  <button
                    className={`btn ${styles.btnTheme} px-4`}
                    onClick={() => setIsDisabled(false)}
                  >
                    Edit Profile
                  </button>
                ) : (
                  <button
                    className={`btn ${styles.btnTheme} px-4`}
                    onClick={handleUpdate}
                  >
                    Save
                  </button>
                )}
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="" className={"form-label pt-2"}>
                    First Name
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"firstName"}
                      defaultValue={firstName}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Last Name
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"lastName"}
                      defaultValue={lastName}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Email
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="email"
                      name={"email"}
                      defaultValue={email}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Phone
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"phone"}
                      defaultValue={phone}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Date of Birth
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="date"
                      name={"dateOfBirth"}
                      defaultValue={dateOfBirth}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Occupation
                  </label>

                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <select
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      value={userData.occupation}
                      onChange={handleChange}
                      name="occupation"
                      disabled={isDisabled}
                    >
                      <option value={occupation._id}>{occupation.name}</option>
                      {selectoccupation.length > 0 && selectoccupation.sort((a, b) => a.name.localeCompare(b.name)).map((el, i) => (
                        <option key={i} value={el._id}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>

                  <label htmlFor="" className={"form-label pt-2"}>
                    Clinic Name
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"clinicName"}
                      defaultValue={clinicName}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label htmlFor="" className={"form-label pt-2"}>
                    Postcode
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"postCode"}
                      defaultValue={postCode}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Address
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"address"}
                      defaultValue={address}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className={"form-label pt-2"}>
                    Web
                  </label>
                  <div
                    className={`input-group flex-nowrap ${styles.formStyle}`}
                  >
                    <input
                      type="text"
                      name={"website"}
                      defaultValue={website}
                      onChange={handleChange}
                      className={`form-control ${styles.inputField} ${
                        !isDisabled && styles.noBorder
                      }`}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <span
                        className={`input-group-text ${styles.adornment}`}
                        id="addon-wrapping"
                      >
                        <BiEditAlt />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
