import React, { useState } from "react";
import image from "../../assets/images/client.png";
import styles from "./NotificationCard.module.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import CustSpinner from "../Spinner/CustSpinner";

const NotificationCard = ({
  unread,
  btn,
  accept,
  notif,
  updtaeNotification,
  isLoading
}) => {

  return (
    <div
      className={
        unread
          ? `${styles.unread} ${styles.notificationContainer} dropdown-item`
          : `${styles.notificationContainer} dropdown-item`
      }
      style={{ display: "flex", alignItems: "center", columnGap: 10 }}
    >
      {notif && btn && (
        <span
          className={`d-flex justify-content-between w-100 ${styles.notificationContainer}`}
        >
          <div className="d-flex">
            {/* <img src={image} alt="" className={styles.notificationImage} /> */}
            <div>
              <p className={"m-0 fw-bold"}>
                {notif.clientId?.firstName + " " + notif.clientId?.lastName}
              </p>
              <p className={"m-0"}>
                {notif.clientId?.firstName} wants to link with you
              </p>
            </div>
          </div>
          <div>
            {btn && (
              <Button
                style={{
                  background: "#9DBEBB",
                  border: "none",
                }}
                className="btn"
                onClick={() => accept(notif?._id)}
                disabled = {isLoading}
              >
                {isLoading ? <CustSpinner /> : "Accept"}
              </Button>
            )}
            <div className={unread ? styles.unreadDot : styles.readDot}>
              {moment(notif.createdAt).fromNow()}
            </div>
          </div>
        </span>
      )}

      {notif && !btn && (
        <span
          className={`${styles.notificationContainer}`}
          onClick={() => updtaeNotification(notif)}
        >
          {/* <img src={image} alt="" className={styles.notificationImage} /> */}
          <div>
            <p className={"m-0 fw-bold"}>{notif.text}</p>
          </div>
          <div className={unread ? styles.unreadDot : styles.readDot}>
            {moment(notif.createdAt).fromNow()}
          </div>
          {/* {<button >check <FiArrowRight></FiArrowRight></button>} */}
        </span>
      )}
    </div>
  );
};

export default NotificationCard;
