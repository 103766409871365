import React from 'react';
import styles from './PrivacyPolicy.module.css'
import {AiOutlineRight} from "react-icons/ai";
import SettingsNav from "../../components/shared/SettingsNav/SettingsNav";

const PrivacyPolicy = () => {


    return (
        <div className={'bg-light'} style={{height: "100vh"}}>
            <div className={'container pt-5'}>
                <h5 className={'pt-5'}> Settings<span className={'activeBreadCrumb'}><AiOutlineRight/> Privacy policy</span>
                </h5>
                <div className="card border-0 p-0 p-md-5 page-container" style={{height: "80vh"}}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div style={{position: "sticky", top: 0}}>
                                <SettingsNav/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="p-3">
                                <h4>Privacy Policy</h4>
                                <br/>
                                <iframe title={'pdf'} src="https://drive.google.com/file/d/1bxJArr85mRRty_bo2vkKLrXHWEYmVUFG/preview" width="100%" height="600px" />
                                {/* <p className={styles.paragraphContainer}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus assumenda
                                    consequuntur cupiditate deleniti eaque est inventore laboriosam minima quae
                                    temporibus tenetur voluptatem, voluptatibus? Accusamus aspernatur assumenda autem
                                    delectus dignissimos dolore dolorum ducimus enim est fuga illo ipsa itaque laborum
                                    magni minima molestiae nam necessitatibus nihil officiis pariatur possimus quam
                                    ratione repellendus repudiandae, similique temporibus voluptates. Adipisci,
                                    doloribus, eligendi enim est ex iste laudantium modi necessitatibus quaerat
                                    quibusdam reiciendis sed ullam voluptatibus! Ab accusamus alias aliquam aliquid
                                    corporis culpa dolorem dolorum earum eius, enim est eum hic id illum impedit labore
                                    laboriosam molestias mollitia nostrum placeat porro provident quaerat quo quod
                                    ratione sapiente sunt velit. Ab, amet animi aperiam at consequuntur corporis
                                    delectus dicta dolore doloremque eaque eligendi eum expedita fuga id iusto magnam
                                    mollitia neque nisi nobis omnis perferendis, placeat quae quas, qui quibusdam quo
                                    rem reprehenderit repudiandae sapiente sequi similique sunt ullam unde veniam
                                    veritatis vero vitae. Amet aut eligendi ex explicabo incidunt nesciunt, numquam
                                    placeat quas sit vel? Ad atque corporis debitis deleniti deserunt dolorem dolores
                                    dolorum earum eius eum excepturi expedita fugit harum hic impedit incidunt ipsam
                                    iure iusto laborum laudantium magni minima molestiae molestias neque nisi numquam
                                    odio pariatur quam ratione recusandae repellendus reprehenderit sunt tempora
                                    temporibus, velit vero voluptatum! Adipisci aspernatur delectus distinctio eius id
                                    iure, perspiciatis praesentium voluptates! Aspernatur assumenda consectetur, est et
                                    harum incidunt laborum officia omnis pariatur possimus praesentium quas qui, quod
                                    rerum saepe sint soluta suscipit tempora? Adipisci deserunt dolores excepturi
                                    explicabo maiores mollitia, pariatur recusandae rem tenetur unde? Ab architecto
                                    assumenda blanditiis dolores eos, inventore magnam porro quisquam rem similique
                                    tenetur, totam? Accusamus ad aut beatae deleniti dignissimos dolorum ea eum, illum
                                    impedit inventore ipsam ipsum iure minima modi molestias neque numquam odio quas qui
                                    quidem recusandae sit sunt tenetur totam, unde? A, aliquam beatae commodi
                                    consectetur consequatur dicta distinctio eius ex harum itaque nobis odio quos, rem
                                    repellendus sunt ullam ut voluptate voluptatem! Adipisci alias, autem consectetur
                                    distinctio dolorum explicabo harum magnam nobis obcaecati officiis placeat quibusdam
                                    vel vitae. Autem ducimus ipsum nisi omnis perferendis voluptas voluptatem. Hic,
                                    numquam, quis! Accusamus ad animi dolore earum ex impedit ipsam iste laboriosam,
                                    laudantium nihil odit perferendis provident quas quisquam quos rerum temporibus
                                    tenetur voluptatem! Ab aliquid at doloremque doloribus eius enim esse eum excepturi
                                    harum illo illum laudantium natus numquam odio optio quidem reiciendis repellat,
                                    sequi similique tenetur unde velit voluptate voluptatem? Aliquid animi assumenda
                                    delectus distinctio dolore dolores eos error ipsam molestias necessitatibus nobis,
                                    officia perspiciatis quasi quis rem temporibus vel vero. Accusamus culpa dolore,
                                    minus nam natus nemo nihil numquam quia reiciendis sapiente! At autem dignissimos
                                    error esse et illum non placeat quaerat quam suscipit? Error exercitationem fugiat
                                    iure laboriosam libero obcaecati reiciendis sed temporibus. Alias aliquid atque
                                    consequuntur dolores eaque natus praesentium repellendus sapiente voluptate
                                    voluptatem. Culpa cum deleniti eligendi et id illo magni, minima molestias, numquam
                                    officia omnis perferendis porro quisquam sapiente sint sunt tenetur? Accusamus
                                    asperiores assumenda consectetur dignissimos ducimus error et eveniet hic illo
                                    incidunt iusto laborum laudantium maiores molestias mollitia necessitatibus
                                    nesciunt, quam ratione repellendus sequi soluta sunt, tempore ut veritatis
                                    voluptates? A aperiam consequuntur, cum esse eum inventore, magnam nostrum quam,
                                    quibusdam rem repellat suscipit velit! Aperiam debitis dolorum eveniet explicabo
                                    quia? Aliquam consectetur consequuntur deserunt distinctio ducimus eius et fugit hic
                                    illo iusto laboriosam laudantium nisi, nulla perferendis placeat porro praesentium
                                    provident quae quia quis quod repellendus rerum saepe tempora, vel veritatis vitae.
                                    Obcaecati, repellendus, suscipit? Consequuntur et incidunt obcaecati placeat
                                    repellat. Ab animi architecto, consequatur cumque ex facilis, fuga, mollitia neque
                                    officiis quis sequi ullam voluptates? Ea nihil praesentium rerum similique
                                    temporibus! Architecto dolor id odio. Aliquid amet aperiam corporis dolorem,
                                    doloremque incidunt nam odit quae repudiandae soluta veritatis voluptatibus. Ad
                                    eveniet repellendus veritatis? A alias aperiam architecto at autem culpa dolore
                                    dolorum esse et, eveniet excepturi exercitationem impedit ipsum iste mollitia non
                                    porro provident quasi rem repudiandae saepe totam velit. Aut in ipsum laboriosam
                                    minima, natus nemo placeat provident tenetur ut velit! Ab cupiditate illum iure,
                                    placeat possimus sunt? A assumenda atque blanditiis corporis cum cupiditate debitis
                                    deleniti dignissimos dolor eligendi eos est, eveniet ex explicabo hic, ipsa itaque
                                    laboriosam magni minima minus necessitatibus nisi obcaecati possimus praesentium qui
                                    quia quidem quisquam quod reprehenderit saepe sunt suscipit tempora temporibus
                                    tenetur unde veritatis voluptate? Ab accusantium aspernatur beatae consectetur
                                    corporis deleniti deserunt eius eligendi esse harum in ipsa ipsum laborum, maiores
                                    optio perspiciatis possimus praesentium qui quia, quisquam rerum sit sunt suscipit,
                                    tempore veritatis vero voluptas! Cumque est, excepturi fugit inventore nam nostrum
                                    numquam officia optio, perspiciatis possimus quidem ratione tenetur.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;