import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../../assets/images/logo.png";
import { api } from "../../../config/api";
import CustSpinner from "../../Spinner/CustSpinner";
import styles from "./ForgotPassword.module.css";
import SuccessModal from "./SuccessModal";

const ForgotPassword = () => {
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("fail");
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    setEmail(data?.email);
    api.post("/provider/forgetPassword", { email: data.email }).then((res) => {
      setStatus(res.data.status);
      if (res.data.status === "success") {
        setMessage(
          "A password reset link has been sent to your registered email address."
        );
        setIsLoading(false);
        setModalShow(true);
      } else if (res.data.status === "fail") {
        setMessage(res.data.message);
        setModalShow(true);
      }
    });
  };
  const navigate = useNavigate();

  return (
    <>
      <Container fluid>
        <Row>
          {/* <Col md={6} className={'px-0 d-none d-md-block'} >
                    <img className={styles.LoginImage} src={auth_bg} alt="" />
                </Col> */}
          <Col md={12} className={`my-auto ${styles.authcontainer}`}>
            <div>
              <div className="text-center ">
                <img className={styles.logo} src={Logo} alt="" />
              </div>
              <div className={"row"}>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <h1 className={`${styles.title} mt-5`}>
                    Forgot your password?
                  </h1>
                  <p className={`${styles.typography} px-5`}>
                    To recover your password, you need to enter <br />
                    your email address and follow the instructions.
                  </p>
                </div>
                <div className="col-md-3"></div>
              </div>
              <Container>
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={3} className="mb-2" />
                    <Col md={6} className="mb-2">
                      <input
                        className={styles.input}
                        type="email"
                        placeholder="E-mail"
                        {...register("email", { required: true })}
                      />

                      <span
                        className={`${errors.email ? styles.visible : ""} ${
                          !errors.email ? styles.error : ""
                        }`}
                      >
                        This field is required
                      </span>
                    </Col>
                    <Col md={3} className="mb-2" />
                  </Row>
                  <Row>
                    <Col md={3} className="mb-2" />
                    <Col md={6} className="mb-2 mt-3">
                      <button type="submit" className={styles.LoginBtn}>
                        {isLoading ? <CustSpinner /> : "Send"}
                      </button>
                    </Col>
                    <Col md={3} className="mb-2" />
                  </Row>
                </form>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      {/*forgot password mail sent successfully modal*/}
      <SuccessModal
        show={modalShow}
        message={message}
        onHide={() => {
          setModalShow(false);
          status === "success" &&
            navigate("/reset-password", { state: { email } });
        }}
      />
    </>
  );
};

export default ForgotPassword;
