import React from 'react';
import {BsCloudUpload} from "react-icons/bs";

const Input = ({accept, onFiles, files, getFilesFromEvent}) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files'
  
    return (
        <label>
            <div className={'text-info text-center pt-4'} style={{cursor: "pointer"}}>
                <BsCloudUpload style={{fontSize: 40}}/>
                <p className={'text-dark'}>Drag & drop your file here or <span
                    className={'text-info'}>browse</span> from your computer</p>
            </div>
            <input
                style={{display: 'none'}}
                type="file"
                accept={accept}
                multiple
                onChange={e => {
                    getFilesFromEvent(e).then(chosenFiles => {
                        onFiles(chosenFiles)
                    })
                }}
            />
        </label>
    );
};

export default Input;