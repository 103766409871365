import React from 'react';
import styles from "./MessageInput.module.css";
import { MdSend } from "react-icons/md";
import { ImAttachment } from "react-icons/im";

const MessageInput = ({ typingHandler, newMessage, sendMessage }) => {
    return (
        <div>
            <div className="input-group flex-nowrap me-xl-2 me-lg-0 position-relative">
                <input type="text" className={`form-control ${styles.searchInput}`} onChange={(e) => typingHandler(e)}
                    value={newMessage}
                    placeholder="Type here" aria-label="search"
                    aria-describedby="addon-wrapping" />
                <span className={`input-group-text ${styles.adornment}`} style={{ cursor: "pointer" }}
                    id="addon-wrapping" onClick={(e) => sendMessage(e)}><MdSend style={{ fontSize: 30, color: "#77ACA2" }} /></span>
                <span className={styles.fileUpload}><ImAttachment /></span>
            </div>
        </div>
    );
};

export default MessageInput;