import React from 'react';
import clientImage from '../../assets/images/client.png'
import { api } from '../../config/api';
import styles from './ProviderData.module.css'
import { setSelectedChat, setChats } from '../../redux/chat/chat';
import { useDispatch, useSelector } from 'react-redux';
import {SiWechat } from 'react-icons/si'
import {  useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const ProviderData = ({ provider }) => {
    const dispatch = useDispatch()
    const { chats } = useSelector(state => state.chat)
    const navigate  = useNavigate()
    const accessChat = async () => {
        try {
            api.post('/chats', { userId: provider._id }).then((res) => {
                if (!chats.find((item) => item._id === res.data._id))
                    dispatch(setChats([res.data, ...chats]));
                     dispatch(setSelectedChat(res.data)) 
                    navigate('/p/message')
            })
        } catch (err) {
            console.log(err)
        }
    
    };

    return (
        <div className={'mb-5'}>
            <h4 className={'mb-3'}>Provider’s information</h4>
            <div className="row">
                <div className="col-lg-6">
                    <div className="d-flex">

                        <img src={provider.imageUri} alt="" className={`${styles.clientProfileImage} me-3`} />
                        <div>
                            <h4 className={'mb-3'}>{provider.firstName + " " + provider.lastName}</h4>
                            <p className={'mb-2'}>{provider.occupation.name}</p>
                            <p className={'mb-2'}><span className={'fw-bold'}>{provider.clinicName}</span></p>
                            {/* <p className={'mb-2'}>Address:  <span className={'fw-bold'}>70 Bright Road, Toowoomba, QLD</span></p> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <p className={'mb-1'}>Email: {provider.email}</p>
                    <p className={'mb-1'}>Phone: {provider.phone}</p>
                    <Button className={'mb-1'} style={{
                            background : "#468189",
                            width: "inherit"
                    }} color="#468189" onClick={accessChat}>Message <SiWechat/> </Button>
                    {/* <p className={'mb-1'}>Web: www.example.com</p> */}
                </div>

            </div>
        </div>
    );
};

export default ProviderData;