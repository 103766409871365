import moment from "moment/moment";
import React, { useEffect, useState , useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChooseSubscriptionCard from "../../components/ChooseSubscriptionCard/ChooseSubscriptionCard";
import InvoiceCard from "../../components/ChooseSubscriptionCard/InvoiceCard";
import {
  createCustomer,
  createSubscription,
  fetchPrices,
  portalSession,
  allSubscription
} from "../../redux/subscription/subscription";
import styles from "./SubscriptionChoose.module.css";
const SubscriptionChoose = ({ navigation }) => {
  const [price, setPrice] = useState(null);
  const [reload, setreload] = useState(false);

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  const { prices, customer, currentSubscription } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   customer && dispatch(allSubscription({ customerId: customer.id }));
  // }, [customer]);
  const createSubscript = (price) => {
    setPrice(price);
    try {
      dispatch(
        createSubscription({
          priceId: price.id,
          domurl: "https://provider.linkmehealth.com.au/p/choose-subscription",
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const Logo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
    >
      <defs />
      <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="0-Default"
          transform="translate(-121.000000, -40.000000)"
          fill="#E184DF"
        >
          <path
            d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
            id="Pilcrow"
          />
        </g>
      </g>
    </svg>
  );
  const ProductDisplay = () => (
    <section>
      <Logo />

      <div className={`row g-2 mt-5 d-flex m-auto ${styles.mainRow}`}>
        <div className={`py-3 ${styles.heading}`}>
          <h3>Choose subscription</h3>
        </div>
        {prices.map(
          (v, i) =>
            v.product.active && v.active && (
              <div className={`col-md-4 p-4 ${styles.mapingCard}`} key={i}>
                <ChooseSubscriptionCard
                  cardType={"standard"}
                  price={v}
                  createSubscription={(e) => createSubscript(e)}
                />
              </div>
            )
        )}
        <p style={{ margin: "60px" }}>None of the plans have lock in contracts. Cancel any plan, anytime, without fee.</p>
      </div>
    </section>
  );

  const SuccessDisplay = () => {
    return (
      <section>
        <InvoiceCard
          currentSubscription={currentSubscription}
          portalSession={() =>
            dispatch(portalSession({ customerId: customer.id }))
          }
        />
      </section>
    );
  };

  useEffect(() => {
    dispatch(fetchPrices());
  
  }, [])
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);
  const date = useMemo(() => moment(currentSubscription?.currentPeriodEnd), [currentSubscription.currentPeriodEnd]);
  const now = useMemo(() => moment(), []);
  if ((success || currentSubscription._id) && date > now) {
    return <SuccessDisplay />;
  } else {
    return <ProductDisplay />;
  }
};

export default SubscriptionChoose;