import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";


export const fetchPrograms = createAsyncThunk(
    'courses/fetchmyPrograms',
    async ({id}) => {
        try {
            const response = await api.get(`/client/getPrograms/${id}`);
            if (response.data.status === 'success') {
                return response.data.data;
               
            }
        } catch (error) {
            console.log(error)
        }
    }
);

export const clientProgramSlicer = createSlice({
    name: 'clientPrograms',

    initialState: {
        clientPrograms: [],
        loading: true,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrograms.fulfilled, (state, { payload }) => {
            if (payload) {
                state.clientPrograms = payload;
                state.loading = false;
               
            }
        })
    },

    reducers: {
        removeMyClients: (state) => {
            state.clientPrograms = null;
            state.loading = false;
        },

    }
})
export const { removeMyClients } = clientProgramSlicer.actions;

export default clientProgramSlicer.reducer;