import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import successfully_sent_mail from "../../../assets/images/forgot_password.gif";
import ReactInputVerificationCode from "react-input-verification-code";
import styles from "./SignUp.module.css";
import verification_success from "../../../assets/images/success_password_change.gif";
import verification_failed from "../../../assets/images/verification_failed.gif";
import { useNavigate } from "react-router-dom";
import CustSpinner from "../../Spinner/CustSpinner";
const VerificationModal = (props) => {
  const propData = { ...props };
  const navigate = useNavigate();
  return (
    <Modal
      show={propData.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {!propData.verifiedSuccess && !propData.verifiedFailed && (
          <Row className={"py-5"}>
            <Col md={3} className="mb-2" />
            <Col md={6} className="mb-2 text-center">
              <div className={""}>
                <img
                  src={successfully_sent_mail}
                  alt=""
                  style={{ maxWidth: 100 }}
                />
              </div>
              <p>
                A verification email has been sent to your registered email
                address.
              </p>
              <div className="custom-styles d-flex justify-content-center my-2">
                <ReactInputVerificationCode
                  length={6}
                  onChange={propData.setPin}
                  value={propData.pin}
                />
              </div>
              <button
                type="submit"
                className={styles.signupBtn}
                onClick={propData.onHide}
                disabled={propData.loading}
              >
                {propData.loading ? <CustSpinner /> : "Verify"}
              </button>
              <p className={`${styles.resendText} pt-4`}>
                Resend verification code
              </p>
            </Col>
            <Col md={3} className="mb-2" />
          </Row>
        )}
        {propData.verifiedSuccess && !propData.verifiedFailed && (
          <>
            <div className={"text-center my-3"}>
              <img
                src={verification_success}
                alt=""
                style={{ maxWidth: 150 }}
              />
              <h5 className={"py-3"}>Verified!</h5>
            </div>
            <Row>
              <Col md={3} className="mb-2" />
              <Col md={6} className="mb-2 my-3">
                <button
                  type="submit"
                  className={styles.signupBtn}

                  onClick={() => {
                    propData.setVerifiedSuccess(false);
                    propData.setVerifiedFailed(false);
                    propData.setModalShow(false);
                    navigate("/p/choose-subscription");
                  }}
                >
                  Done
                </button>
              </Col>
              <Col md={3} className="mb-2" />
            </Row>
          </>
        )}
        {!propData.verifiedSuccess && propData.verifiedFailed && (
          <>
            <div className={"text-center my-3"}>
              <img src={verification_failed} alt="" style={{ maxWidth: 150 }} />
              <h5 className={"py-3"}>A verification attempt has failed!</h5>
            </div>
            <div className={"text-center"}>
              <p className={`${styles.resendText} pt-4`}>
                Resend verification code
              </p>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VerificationModal;
