import { Spinner } from "react-bootstrap";

export default function ConditionalSpinner({loading, children}){
    if (loading) return <Spinner style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        margin: " 10% auto"

    }}></Spinner>
    else return children
}