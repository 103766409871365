import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";

export const fetchMyClients = createAsyncThunk(
  'myClients/fetchMyClients',
  async () => {
    try {
      const response = await api.get('/provider/myClientswithOthPro');
      if (response.data.status === 'success') {
        // const sortedClients = response.data.data
          // .flatMap((client) => client.clients)
          // .sort((a, b) => a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }));

        // const sortedMyClients = response.data.data.map((client) => ({
        //   ...client,
        //   clients: sortedClients.filter((sortedClient) =>
        //     client.clients.includes(sortedClient)
        //   ),

        // }));
        // console.log(sortedMyClients, 'here is the');
        // return sortedMyClients
        return response.data.data
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
    }
  }
);

export const fetchlinkedProvider = createAsyncThunk(
  'myClients/fetchlinkedProvider',
  async (id) => {
    try {
      const response = await api.get(`/provider/linkedProvider/${id}`);
      
      return response.data.status === 'success' ? response.data.data : [];
    } catch (error) {
      console.log(error)
    }
  }
);

export const myClientsSlice = createSlice({
  name: 'myClients',

  initialState: {
    myClients: [],
    linkedProvider: [],
    myClientById: {},
    loading: true,
  },

  reducers: {
    removeMyClients: (state) => {
      localStorage.removeItem('myClients');
      state.myClients = null;
      state.loading = false;
    },

    getMyClientById: (state, { payload }) => {
      state.myClientById = payload;
      state.loading = false;
    },

    removeMyClientsById: (state) => {
      localStorage.removeItem('myClientById');
      state.myClientById = null;
      state.loading = false;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMyClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMyClients.fulfilled, (state, { payload }) => {
        state.loading = false;
       
        state.myClients = payload;
      })
      .addCase(fetchlinkedProvider.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchlinkedProvider.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.linkedProvider = payload;
      });
  }
});

export const { removeMyClients, getMyClientById } = myClientsSlice.actions;

export default myClientsSlice.reducer;
