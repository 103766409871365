import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useState } from 'react';
import S3 from 'react-aws-s3';
import { Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../config/api';
import { s3Config } from '../../config/config';
import Input from "./Input";
import Layout from "./Layout";
import Preview from "./Preview";
const getFilesFromEvent = e => {
    return new Promise(resolve => {
        getDroppedOrSelectedFiles(e).then(chosenFiles => {
            resolve(chosenFiles.map(f => f.fileObject))
        })
    })
}
const FileUploader = ({accepts, path , nav , type}) => {
    const [fileUploaded, setFileUploaded] = useState(0)
    const [loading , setLoading] =  useState(false)
    const { clientId } = useParams()
    const navigate  = useNavigate()
    const handleChangeStatus = ({ meta, remove }, status) => {

        setFileUploaded(fileUploaded + 1);
    };
    const handleSubmit = async (files, allFiles) => {
        setLoading(true)
        const bucketconfig = { ...s3Config, dirName: 'resources' }
        const ReactS3Client = new S3(bucketconfig);
        const uploadPromises = files.map(async(file) => {
            const newFileName = file.file.name.replace(/\s+/g, "_");
            return ReactS3Client.uploadFile(file.file, newFileName).then((res)=> {
                return res
            })
        });

        try {
            const uploadedFiles = await Promise.all(uploadPromises);
            const location =  uploadedFiles.map((v) => {
                return {
                filePath : v.location,
                fileName :  v.key?.split('/')?.[1],
                fileType :  v.key?.split('.')[1]
            }})
         
            if(location.length > 0){
                api.post(path , {
                    [type] : location,
                    clientId :  clientId,
                }).then((res)=> {
                    setLoading(true)
                    toast('File successfully uploaded', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    // toast.success('File successfully Uploaded')
                    navigate(nav)}).catch((err) => toast.error("Some thing went wrong"))
            }

        } catch (error) {
            setLoading(false)
            console.error('Upload error:', error);
        }

        // allFiles.forEach(f => f.remove());
    };
    return (
        <div>
            {loading && <Spinner/>}
            <Dropzone
                onChangeStatus={handleChangeStatus}
                LayoutComponent={Layout}
                accept={accepts}
                onSubmit={handleSubmit}
                InputComponent={Input}
                PreviewComponent={Preview}
                getFilesFromEvent={getFilesFromEvent}
                styles={{
                    dropzone: {
                        width: "100%",
                        maxWidth: 700,
                        height: "100%",
                        maxHeight: 250,
                        border: "1px dashed #56CCF2",
                        overflow: "hidden"
                    },
                    dropzoneActive: { borderColor: 'red' },
                }}
            />
        </div>
    );
};

export default FileUploader;
