import React from 'react';
import styles from './MyPrograms.module.css'
import ProgramCard from "../../components/ProgramCard/ProgramCard";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { fetchProgram } from '../../redux/provider/provider';
import { Link, useParams } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
const MyPrograms = () => {
    // const navigate =  useNavigate()
    const dispatch = useDispatch()
    const { clientId } = useParams();
    // const {_id} = useSelector(state=>state.myClients.myClientById) 
    // const storedClientId = useSelector(state => state.myClients.clientId)
    const { myPrograms } = useSelector(state=>state.connectedProviders) 
    useEffect(()=>{
        if(clientId) dispatch(fetchProgram(clientId))
    },[clientId])

    return (
        <div className="col-lg-9">
        <Link className={`position-absolute ${styles.addButton}`} to={`/p/client/my-programs/create-program/${clientId}`}><BsPlusLg/></Link>
        <div className="p-3">
            <h4>Programs</h4>
             <ProgramCard program={myPrograms} clientId ={clientId} />                                   
        </div>
    </div>
    );
};

export default MyPrograms;